import * as React from "react";

const RedirectPage : React.FC<JSX.Element> = () => {
    return (
        <>
            <div>Redirect page.</div>
        </>
    )
}

export default RedirectPage