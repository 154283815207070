import React, {useEffect, useState} from "react";
import "../../css/views/projects/new-project.scss";
import {FaProjectDiagram} from "react-icons/fa";
import {
    addUnderscores,
    capitalizeFirstLetters,
    generateRandomNumber,
    splitDate,
    splitString, validateCurrencyValue
} from "../../helpers/helperFunctions";
import DatePicker from "react-datepicker";
import Switch from "../../components/switch";
import { AiFillDollarCircle } from "react-icons/ai";
import Spinner from "../../components/spinner";
import { clearProjects } from "../../redux/actions";
import store from "../../redux/store";
import {useNavigate} from "react-router-dom";
import {createNewProject, getUserProjects, setAndDispatchProjects} from "../../helpers/apiMethods.ts";

const NewProject = () => {
    let navigate = useNavigate();


    const fields = ['title', 'status', 'description', 'startDate', 'endDate', 'client', 'budget'];

    const fullWidthFields = ['description'], dollarFields = ['budget'],
            switchFields = ['status'];
   // const intFields = ['assignees'];
    const switchText = [
        {
            field: 'status',
            labels: ['Inactive', 'Active']
        }
    ]



    const getSwitchText = field => {
        let data = switchText.find(item => item.field === field);
       // console.log('Data:', field, data)
        return data ? data.labels : null
    }

    const returnFieldType = field => {
        return field.toLowerCase().includes('date') ? 'date' : switchFields.indexOf(field) !== -1 ? 'switch' : 'text'
    }

    let fieldItems = fields.map(field => {
        let dateObject = new Date();
        let todaysDate =  dateObject.toISOString().slice(0, 10);
        let dateData = field === 'startDate' ? todaysDate : new Date(dateObject.getFullYear(), dateObject.getMonth()+1, dateObject.getDate()).toISOString().slice(0, 10);
        let fieldType =returnFieldType(field);
        return {
            label: capitalizeFirstLetters(splitString(field)),
            fullWidth: fullWidthFields.indexOf(field) !== -1,
            placeholder: `Project ${splitString(field).toLowerCase()}`,
            textArea: field === 'description',
            dollarField: dollarFields.indexOf(field) !== -1,
            type: fieldType,
            value: fieldType === 'date' ? dateData : fieldType === 'switch' ? false : '',
            key: field,
            hasError: false,
            errorText: ""
        }
    })

    //console.log('Field items:', fieldItems)
    const [fieldData, updateFieldData] = useState(fieldItems);
    const [creatingProject, setCreatingProject] = useState(false);
    const [createDisabled, setCreateDisabled] = useState(true);
    const [createError, setCreateError] = useState(false);

    useEffect(() => {
        let fieldsWithFeedback = fieldData.filter(field => field.errorText !== '');

        if (fieldsWithFeedback) {
            if (fieldsWithFeedback.length > 0) {
                setTimeout(() => {
                    let fieldItems = [...fieldData];

                    fieldItems.map(field => {
                        field.errorText = '';
                        return field
                    })
                    updateFieldData([...fieldItems])
                }, 2500)
            }
        }


    }, [fieldData])

    useEffect(() => {
        let fieldsWithError = fieldData.filter(field => field.hasError || field.value === '').length > 0;

       setCreateDisabled(fieldsWithError)
    }, [fieldData])


/*
    const validateInteger = (value) => {
        let intData = generateStringIntegerArray(), valueItem = value.toString();
        let returnValue = '';

        if (value !== '') {
            let lastValue = valueItem[valueItem.length-1];
            let slicedValue = valueItem.slice(0, valueItem.length-1);

            if (intData.indexOf(lastValue) === -1) {
                returnValue = slicedValue ? parseInt(slicedValue) : ''
            } else {
                returnValue = parseInt(valueItem);
            }
        }

        return returnValue

    }*/

    const checkDateDifference = (startDate, endDate) => {
        return (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)
    }

    const setValue = (value, index) => {

        let fieldItems = [...fieldData];
        let key = fieldItems[index].key;

        if (dollarFields.indexOf(key) !== -1) {
            value = validateCurrencyValue(value)
        }

       /* if (intFields.indexOf(key) !== -1) {
            value = validateInteger(value)
            /!*
                Alternate less complicated but a little bit less functional method
                value = value !== '' ? parseInt(value) : ''
            *!/
        }*/
        fieldItems[index].hasError = value === ''

        if (key === 'startDate' || key === 'endDate') {
            let endDate = key === 'endDate' ? value : fieldData.find(field => field.key === 'endDate').value;
            let startDate = key === 'startDate' ? value : fieldData.find(field => field.key === 'startDate').value;

            if (startDate && endDate) {
               /* console.log({
                    'End date': endDate.value,
                    'Start date': startDate.value
                })*/
                let dateDifference = checkDateDifference(startDate, endDate);
               // console.log('Date difference:', dateDifference)

                if (dateDifference <= 0) {
                    fieldItems[index].hasError = true;
                    fieldItems[index].errorText = "Start date cannot be ahead of end date"
                } else {
                    fieldItems[index].hasError = false;
                    fieldItems[index].errorText = ''
                }
            }
        }
        fieldItems[index].value = value
        updateFieldData([...fieldItems])
    }

    const handleCreate = (event) => {
        event.preventDefault();

       // console.log('Form submitted', fieldData);

        let emptyFields = fieldData.filter(field => field.value === '' || field.hasError || field.errorText !== '');

        if (emptyFields.length > 0) {
           // console.log('Empty fields:', emptyFields);

            let fieldItems = [...fieldData];
            fieldItems.map(field => {
                field.hasError = field.value === '' || field.errorText !== '' || field.hasError

                return field
            })

            updateFieldData([...fieldItems])
        } else{
            setCreatingProject(true);
            setCreateError(false);
            let projectObject = {
                id: generateRandomNumber(0, 999999999),
                title: "",
                data: {},
                tasks: {
                    backlog: [],
                    inProgress: [],
                    inReview: [],
                    done: []
                }
            }


            fieldData.map(field => {
                if (field.key === 'title') {
                    projectObject.title = field.value;
                } else {
                    const fieldKey = field.key === 'status' ? 'active' : field.key;
                    projectObject.data[fieldKey] = field.value // field.dollarField ? `$${field.value}` : field.value;
                }

                return field
            })

            let requestItem = {...projectObject, ...projectObject.data};
            let user = store.getState().user
            let requestObject = {id: user.id};

            let requestKeys = Object.keys(requestItem);
            let uncheckedFields = ['data', 'tasks', 'id'];

            requestKeys.forEach(key => {
                if (uncheckedFields.indexOf(key) === -1) {
                    requestObject[addUnderscores(key)] = requestItem[key]
                }
             })

          //  let keys = Object.keys(projectObject).map(key => addUnderscores(key));
           // console.log({ requestObject })

            setCreateDisabled(true);

            createNewProject(requestObject, user.token).then(response => {
                setCreateDisabled(false);
                setCreatingProject(false);
                    getUserProjects(user.id, user.token).then(projects => {
                        store.dispatch(clearProjects())
                        setAndDispatchProjects(projects.data)

                        navigate('/projects')
                    })


            }).catch(error => {
                setCreateDisabled(false);
                setCreatingProject(false);
                setCreateError(true)


              //  console.log({ error })
            })

            /*setTimeout(() => {
                if (store.getState()['projects'].length === 0) {
                    store.dispatch(createProjects(dataFormat));
                }
                console.log('Project object:', projectObject)
                store.dispatch(createProject(projectObject))

                navigate('/projects')
            }, 1000)*/




        }
    }


    return (
        <>
            <div className={'container container-md centered create-new'}>
                <div className={'view-header'}>
                    <div className={'project-icon'}>
                        <FaProjectDiagram className={'icon'}/>
                    </div>

                    <header>Create new project</header>
                </div>

                <form onSubmit={event => handleCreate(event)}>
                    <div className={'form-groups'}>
                        {
                            fieldData.map((field, index) => {
                                let switchData = field.type === 'switch' ? getSwitchText(field.key) : null;
                                return (
                                    <div className={`input-control input-group ${field.fullWidth ? 'full-width' : ''}`} key={index}>

                                        {
                                            field.hasError && field.errorText !== '' ? <div className={'tooltip above error'}>
                                                <div className={'content'}>{ field.errorText }</div>
                                            </div> : null
                                        }
                                        <label>{ field.label }</label>
                                        {
                                            field.type === 'date' ? <DatePicker
                                                className={`${field.hasError ? 'input-error' : ''}`}
                                                selected={splitDate(field.value)}
                                                onChange={date => setValue(date.toISOString().slice(0, 10), index)}
                                            /> : field.type === 'switch' ? <div className={'flexbox align-center switch-group'}>
                                                                             { switchData !== null ? <span className={'switch-label'}> { switchData[0] } </span> : null }
                                                                                <Switch
                                                                                    checked={false}
                                                                                    stateChange={value => setValue(value, index)}/>

                                                                                { switchData !== null ? <span className={'switch-label'}> { switchData[1] } </span> : null }
                                                                            </div>: <div className={'input-container'}>
                                                                                        {
                                                                                            field.dollarField ? <AiFillDollarCircle className={'dollar-icon'} /> : null
                                                                                        }
                                                                                        {
                                                                                            field.textArea ? <textarea
                                                                                                            value={field.value}
                                                                                                            placeholder={field.placeholder }
                                                                                                            className={field.hasError ? 'input-error': ''}
                                                                                                            onChange={event => setValue(event.target.value, index)}
                                                                                                        /> : <input
                                                                                                                value={field.value}
                                                                                                                placeholder={field.placeholder}
                                                                                                                className={field.hasError ? 'input-error': ''}
                                                                                                                onChange={event => setValue(event.target.value, index)}
                                                                                                                />
                                                                                        }
                                                                                    </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        createError ? <div className={'error-section'}>
                            An unexpected error occurred.
                        </div> : null
                    }
                    <div className={'button-container'}>
                        <button type={'submit'} className={'button button-main'}  disabled={createDisabled}>
                            {
                                creatingProject ?
                                    <Spinner className={'spinner-icon'} size={20} color={'#f2f2f2'} thickness={1}/>
                                    : <span>Create</span>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default NewProject
