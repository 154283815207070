import React, {useCallback, useEffect, useRef, useState} from "react";
import '../../css/views/register.scss'
import Spinner from "../../components/spinner";
import {setFieldBg} from "./functions/functions";
import {ReactComponent as CircleUser} from "../../assets/icons/circle-user.svg";
import {ReactComponent as SquareEnvelope} from "../../assets/icons/square-envelope.svg";
import {ReactComponent as Plane} from "../../assets/icons/plane-departure.svg";
import {ReactComponent as Checkmark} from "../../assets/icons/checkmark.svg";
import {ReactComponent as ArrowRight} from "../../assets/icons/angle-right.svg";
import {useNavigate} from "react-router-dom";
import { baseApi, apiClient } from "../../api/client";
import store from "../../redux/store";
import {addUser} from "../../redux/actions";
import { cloneDeep } from "lodash";

const Register = () => {
    let navigate = useNavigate()
    const stages = [
          {
              stageId: "registerForm",
              buttonText: "Create account",
              stageLabel: "Personal details",
              stageDesc: "",
              fields: [
                  {
                      type: "text",
                      name: "firstname",
                      placeholder: "First name",
                      halfWidth: true,
                      hasError: false
                  },

                  {
                      type: "text",
                      name: "lastname",
                      placeholder: "Last name",
                      halfWidth: true,
                      hasError: false
                  },

                  {
                      type: "email",
                      name: "email",
                      placeholder: "Email address",
                      halfWidth: false,
                      hasError: false
                  },

                  {
                      type: "password",
                      name: "password",
                      placeholder: "Password (minimum of 8 characters)",
                      halfWidth: false,
                      hasError: false
                  },

                  {
                      type: "password",
                      name: "password_confirmation",
                      placeholder: "Enter your password again",
                      halfWidth: false,
                      hasError: false
                  }
              ]
          }
      ,


          {
              stageId: "verificationForm",
              buttonText: "Verify",
              stageLabel: "Email verification",
              stageDesc: "We sent a code to your email address, Please enter that code here. This code expires in 30 minutes.",
              fields: [
                  {
                      type: "text",
                      name: "code",
                      placeholder: "Verification code",
                      halfWidth: false,
                      hasError: false,
                      errorText: ""
                  },
              ]
          }
      ,

        {}
    ];

    let stageHeaders = [
        <CircleUser className={"stage-icon"}/>,
        <SquareEnvelope className={"stage-icon"}/>,
        <Plane className={"stage-icon"}/>
    ]

    const [gettingStatus, setGettingStatus] = useState(true);

    let headerText = useRef(["Let's get you started!", 'Almost there!', 'Done!'])
    useEffect(() => {
        let savedUser = localStorage.getItem('savedUser');
       // console.log('Saved user:', savedUser);


        if (savedUser == null){
            setGettingStatus(false)
        } else {
            navigate('/dashboard')
        }

        //eslint-disable-next-line
    }, []);



    const registrationData = useRef({});


   /* useEffect(() => {
        console.log('Data changed to:', registrationData.current)
    }, [registrationData])*/

    const RegisterUser = () => {
        const [registerFields, updateRegisterFields] = useState(stages);
        const [loadingResults, setLoadingResults] = useState(false);
        const [headers] = useState(stageHeaders);
        const fieldItems = useRef([]);
        const [countDown, updateCountDown] = useState(3);
        const [errors, setErrors] = useState([]);
        const [resendingCode, setResendingCode] = useState(false);
        const [resendText, setResendText] = useState("Resend code");
        const [verifiable, setVerifiable] = useState(false);
        const [currentStage, setCurrentStage] = useState(0);


        useEffect(() => {
            setTimeout(() => {
                setResendText("Resend code");
            }, 5000)
        }, [resendText])


        const resendCode = () => {
            setResendingCode(true)

           // console.log('Data:', registrationData.current)

            const requestObject = { user: registrationData.current.data.id }

            apiClient.post('send-code', requestObject, {
                headers: {
                    'Accepts': 'application/json',
                    'Authorization': `Bearer ${registrationData.current.token}`
                }
            }).then(response => {
                registrationData.current.data.verification = response.data

                setResendingCode(false)
                setResendText("Code resent!")
               // console.log('Response:', response)


            }).catch(error => {
                setResendingCode(false)
                setResendText('Error sending code');
               // console.log('Error sending code:', error)
            })
        }


        const handleSubmit = (event, stageIndex) => {
            event.preventDefault();

            console.log('Submitted')

            const validateAndRegisterUser = () => {
                    setLoadingResults(true);
                    setErrors([...[]]);

                    if (currentStage === 0) {
                        let requestObject = {};

                        fieldItems.current[0].map(field => {
                            requestObject[field.name] = field.value

                            return field
                        });

                       // console.log('Request object:', requestObject);
                        baseApi.post("register", requestObject, {
                            headers: {
                                'Accepts': 'application/json'
                            }
                        }).then(response => {
                            setLoadingResults(false)

                            if (response.status === 200) {

                                // console.log('API response:', response);
                                registrationData.current = {
                                    token: response.data['userToken'],
                                    data: {
                                        id: response.data.userId,
                                        verification: response.data['verification']
                                    }
                                }

                             //   console.log({ data: registrationData.current })


                                setCurrentStage(1);

                            }
                        }).catch(error => {
                           // console.log('Error:', error.response.data.errors);

                            if (error.response) {
                                if (error.response.data) {
                                    if (error.response.data.errors) {
                                        setLoadingResults(false)
                                        let errors = error.response.data.errors;
                                        let keys = Object.keys(errors);

                                        let errorData = keys.map(key => {
                                            let values = errors[key].map(item => Object.values(item).join(","));
                                            return values.join(",");
                                        });

                                        setErrors(errorData);
                                        setLoadingResults(false)
                                    } else {
                                        setErrors([error.message])
                                        setLoadingResults(false)
                                        setLoadingResults(false)
                                    }
                                } else {
                                    setErrors(['An unexpected error occurred']);
                                    setLoadingResults(false)
                                }

                            } else {
                                setErrors(['An unexpected error occurred']);
                                setLoadingResults(false)
                            }

                        })
                    }
                    //  setCurrentStage( currentStage + 1)
            }

            const verifyUser = () => {
               /* console.log('Verification fields:', )
                console.log('Registration:', registrationData.current)*/

                let codeField = fieldItems.current[currentStage][0];

                if (!verifiable) {

                    if (codeField.value.length < 6) {
                        let fieldData = [...registerFields]
                        fieldData[currentStage].fields[0].hasError = true;
                        fieldData[currentStage].fields[0].errorText = "Code should be 6 characters long"

                        updateRegisterFields(fieldData)
                    } else {
                        handleChange(registerFields[currentStage].fields[0], currentStage, 0, codeField.value)
                    }

                } else{
                    setLoadingResults(true)
                    apiClient.post("verify-user", {
                        userId: registrationData.current.data.id
                    }, {
                        headers: {
                            'Accepts': 'application/json',
                            'Authorization': `Bearer ${registrationData.current.token}`
                        }
                    }).then(response => {
                        setLoadingResults(false);

                       // console.log('User response:', response)
                        setCurrentStage( currentStage + 1)
                    })

                }

             /*   axios.post('http://127.0.0.1:8000/api/task-management/verify-code', {}, {
                    headers: {
                        'Authorization': `Bearer ${userToken.current}`
                    }
                }).then(response => {
                    console.log('Response:', response)
                }).catch(error => {
                    console.log('Error:', error)
                })*/
            }



            let stageFields = fieldItems.current[stageIndex];
            let failedValidationFields = [];

            let nullValues = stageFields.map((element, index) => {


                if (element.value === '') {
                    failedValidationFields.push(index)
                    return -1
                } else if (element.getAttribute('type') === 'password' && element.value.length < 8) {
                    failedValidationFields.push(index)
                    return -1
                } else {
                    return 1
                }
            }).filter(value => value === -1);

            if (nullValues.length === 0) {
                if (currentStage === 0)  {
                    validateAndRegisterUser()
                } else {
                    verifyUser()
                }
            }

            if (failedValidationFields.length > 0) {

                let fieldTabs = cloneDeep(registerFields);
                fieldTabs[stageIndex].fields = fieldTabs[stageIndex].fields.map((field, index) => {
                    if (failedValidationFields.indexOf(index) !== -1) {
                        field.hasError = true;

                        if (field.type === 'password' ) {
                            setErrors(['Password should be at least 8 characters long'])
                        }
                    }

                    return field
                })


                updateRegisterFields(fieldTabs)
            }
        }

       const reduceCountdown = useCallback(() => {
               setTimeout(() => {
                   updateCountDown(countDown - 1);
               }, 1000)
       }, [countDown]);

        useEffect(() => {
            if (currentStage === registerFields.length -1) {
                reduceCountdown()
            }
        }, [currentStage, registerFields.length, reduceCountdown])


        useEffect(() => {
           if (currentStage === registerFields.length-1) {
              // console.log('On last stage')
               if (countDown === 1) {
                   setTimeout(() => {
                       localStorage.setItem('userToken', registrationData.current.token)
                       let userObject = {
                           id: registrationData.current.data.id,
                           token: registrationData.current.token
                       }
                       localStorage.setItem('savedUser', JSON.stringify(userObject));

                       store.dispatch(addUser(userObject))

                       navigate('/dashboard')
                   }, 1000)
               } else {
                   reduceCountdown()
               }
           }
        }, [countDown, reduceCountdown, currentStage, registerFields.length])

       /* useEffect(() => {
            if (currentStage === registerFields.length - 1) {
                console.log('On last stage')
                //memoizedCountDown()
            }
        }, [memoizedCountDown, registerFields.length])

        useEffect(() => {
            if (countDown === 1) {
                clearInterval(countDownState.current);

                setTimeout(() => {
                    navigate('/dashboard')
                }, 1000)

            } else {
                if (currentStage === registerFields.length -1) {
                    memoizedCountDown()
                }
            }
        }, [countDown, memoizedCountDown, registerFields.length]);*/


        const handleChange = (field, stageIndex, index, value = null) => {
           // console.log('Field changed')
            const removeError = () => {
                if (field.hasError) {
                    let fieldItems = cloneDeep(registerFields)
                    fieldItems[stageIndex].fields[index].hasError = false;

                    updateRegisterFields(fieldItems)
                }
            }

            const setCodeErrorText = (errorText) => {
                let fieldItems = cloneDeep(registerFields);

                fieldItems[stageIndex].fields[index].hasError = true;
                fieldItems[stageIndex].fields[index].errorText = errorText;

                updateRegisterFields(fieldItems)
            }


           if (value === null) {
               removeError()
           } else{
               if (value.length < 6 && field.hasError) {
                   removeError()
               } else {
                   if (value.length >= 6) {
                      // console.log('Value length:', value.length)
                       let savedCode = registrationData.current.data.verification.code
                       if (value !== savedCode) {
                          setCodeErrorText("Incorrect code")
                       } else {
                            let issueTime = registrationData.current.data.verification['issuedAt'];
                            let dateDifference = Math.floor((new Date() - new Date(issueTime)) / (1000 * 60)) + new Date().getTimezoneOffset();

                          if (dateDifference > 30) {
                              setCodeErrorText("This code has expired. Please request a new one")
                          } else {
                              setVerifiable(true)
                              if (field.hasError) { removeError() }
                          }
                       }
                   } else{
                       removeError()
                   }
               }
             //  console.log('On verification code')
           }
        }

      /*  const checkCodeCondition = (stageIndex, index) => {
            let field = fieldItems.current;
            let codeValue = registrationData.current.data

            console.log('Function called')

            if (field && codeValue) {
                if ( field[stageIndex] ) {
                    let value = field[stageIndex][index].value
                    if (value.length < 6) {
                        return false
                    } else {
                        return value === codeValue.verification.code
                    }
                } else {
                    return false
                }
            } else {
                return false
            }

        }*/


        const fieldsWithError = (stageIndex) => {
           return stageIndex !== stages.length - 1 ? registerFields[stageIndex].fields.filter(field => field.hasError).length : 0;
        }



        return  <div className={"full-width register-container"}>
            <div className={"container container-sm centered form-container"}>
                <header className={"text-center"}>{ headerText.current[currentStage] }</header>

                <div className={"stages flexbox align-center"}>
                    {
                        headers.map((icon, index) => {
                            return (
                                <div
                                    key={`icon-${index}`}
                                    className={`${index !== 0 ? "splitter-item" : ""}`}
                                >
                                    {index !== 0 ? <div className={"splitter"}/> : null}

                                    <div
                                        key={index}
                                        className={`stage-item ${ index <= currentStage ? 'active-stage': ''}`}
                                        onClick={() => {
                                            if( index < currentStage) {
                                                setCurrentStage( index )
                                            }
                                        }}>
                                        { icon }
                                    </div>
                                </div>

                            )
                        })
                    }

                </div>

                {
                    registerFields.map((stage, stageIndex) => {
                        return (
                            <div key={stageIndex}>
                                <div className={`header-column ${currentStage !== stageIndex ? 'hidden': ''}`}>
                                    <header className={`stage-label`}>{ stage.stageLabel }</header>

                                    <p className={ 'stage-desc' } >{ stage.stageDesc }</p>
                                </div>

                                {

                                    stageIndex !== registerFields.length -1 ?  <form  id={stage.stageId} className={`main-form flexbox ${stageIndex  !== currentStage ? 'hidden' : ''}`} onSubmit={(event) => handleSubmit(event, stageIndex)}>
                                        {
                                            stage.fields.map((field, index) => {
                                                return (
                                                    <div className={`input-control relative-item ${field.halfWidth ? 'half-item' : ''}`} key={index}>
                                                        {
                                                            field.hasError && field.errorText && field.errorText !== '' ?
                                                                <div className={'tooltip above error'}>
                                                                    <div className={'content'}>{ field.errorText }</div>
                                                                </div> : null
                                                        }
                                                        <input
                                                            className={`${ field.hasError ? 'input-error'  : ''}`}
                                                            ref={ element => {
                                                                if (fieldItems.current[stageIndex] === undefined) {
                                                                    fieldItems.current[stageIndex] = []
                                                                }

                                                                if (element !== null && fieldItems.current[stageIndex].indexOf(element) === -1) {
                                                                    fieldItems.current[stageIndex].push(element)
                                                                }
                                                            }
                                                            }
                                                            type={ field.type }
                                                            id={ field.name }
                                                            name={ field.name }
                                                            onBlur={() => {
                                                                let fieldValue = fieldItems.current[stageIndex][index].value;
                                                                if (stageIndex !== 1) {
                                                                    setFieldBg(fieldItems.current[stageIndex][index], fieldValue)
                                                                }
                                                            }}
                                                            onChange={() => {
                                                               if (field.name === 'code') {
                                                                   let fieldInput = fieldItems.current[stageIndex].find(input => input.name === 'code');

                                                                   if (fieldInput) {
                                                                       handleChange(field, stageIndex, index, fieldInput.value)
                                                                   }
                                                               } else {
                                                                   handleChange(field, stageIndex, index);

                                                                   if (errors.length > 0) {
                                                                       setErrors([])
                                                                   }
                                                               }
                                                            }}
                                                            placeholder={field.placeholder}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            errors.length > 0 ? <div className={'error-section'}>
                                                <ul>
                                                    {
                                                        errors.map((error, index) => {
                                                            return <li key={index}>{ error }</li>
                                                        })
                                                    }
                                                </ul>
                                            </div> : null
                                        }

                                        <div className={`button-container full-width ${ currentStage === 1 ? 'verify-stage': ''}`}>
                                            <button type={"submit"} className={"button button-regular flexbox justify-center align-center full-width"} disabled={fieldsWithError(currentStage) > 0}>
                                                {!loadingResults ? <div><span>{ stage.buttonText }</span> <ArrowRight /> </div>: <Spinner size={16} color={'#FFFFFF'} thickness={1}/> }</button>
                                        </div>

                                        {
                                            currentStage === 1 ? <div className={'resend-link flexbox'} onClick={() => resendCode()}><span>{ resendText }</span> { resendingCode ? <Spinner size={16} color={'#FFFFFF'} thickness={1}/> : null }</div> : null
                                        }
                                    </form> : <div className={`flexbox column align-center success-stage ${currentStage !== stageIndex ? 'hidden': ''}`}>

                                        <div className={"animate-container relative-item"}>
                                            <div className={" success-animate rounded"}/>
                                            <Checkmark />
                                        </div>

                                        <div className={'success-text'}>Your account has been activated!</div>
                                        <div className={'redirect-status'}>You will be redirected in</div>

                                        <div className={'countdown'}>{ countDown }</div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }



            </div>
        </div>
    }


    return (
        gettingStatus ? <div className={'full-width flexbox justify-center align-center'} style={{height: '100vh'}}>
            <Spinner size={30} color={'#FFFFFF'} thickness={1}/>
        </div> : <RegisterUser/>
    )
}

export default Register;
