import * as actions from './actionTypes';
import produce from "immer";

let defaultState = {
    user: {},
    projects: [],
    timeline: [],
    meetings: [],
    users: []
}

const reducer = (state = defaultState, action) => {
    let appState = {...state};
    switch (action.type) {
        case actions.TIMELINE_CREATED:
            appState.timeline = action.payload.timeline
            return appState
        case actions.SAVE_USER:
            appState.user = action.payload.user
            return appState
        case actions.PROJECTS_CREATED:
           // console.log('Create project action called');
            appState.projects = action.payload.projects
            return appState
        case actions.CREATE_MEETING:
            appState.meetings.push(action.payload.data)
            return appState
        case actions.UPDATE_TASK_ORDER:
            // console.log('New state:', newState)
            return produce(state, draft => {
                let projectItem = draft.projects.find(project => project.id === action.payload.id);

                if (projectItem) {
                    let projectIndex = draft.projects.indexOf(projectItem);
                    draft.projects[projectIndex].tasks = action.payload.tasks;
                }
            })
        case actions.UPDATE_PROJECT:
            return produce(state, draft => {
                let project = draft.projects.find(project => project.id === action.payload.project.id);

                if (project) {
                    let index = draft.projects.indexOf(project);
                    draft.projects[index] = action.payload.project
                }
            })
        case actions.DELETE_PROJECT:
            console.log('Project id:', action.payload.id)
            return produce(state, draft => {
                draft.projects = draft.projects.filter(project => project.id !== action.payload.id);
            })
        case actions.ADD_TASK:
            return produce(state, draft => {
                let project = draft.projects.find(project => project.id === action.payload.id);

                if (project) {
                    let projectIndex = draft.projects.indexOf(project);

                    draft.projects[projectIndex][action.payload.type] = action.payload.data;
                    console.log('New task added to redux store')
                }
            })
        case actions.CREATE_PROJECT:
            return produce(state, draft => {
                //draft.projects.push(action.payload.data);
                draft.projects = [...draft.projects, action.payload.data];
                console.log('New project created!')


                return draft
            })
        case actions.ADD_USER:
            return produce(state, draft => {
                draft.user = action.payload.data
            })
        case actions.ADD_MEMBER:
            return produce(state, draft => {
                draft.users = [...draft.users, action.payload.member]
            })
        case actions.CLEAR_PROJECTS:
            return produce(state, draft => {
                draft.projects = []
            })
        case actions.CLEAR_MEMBERS:
            return produce(state, draft => {
                draft.users = []
            })
        case actions.UPDATE_TASK_LIST:
            let stateItem = {...state};
            let stateProjects = [...stateItem.projects];

           stateProjects = stateProjects.map(project => {
               let projectItem = {...project};
                if (project.id === action.payload.id) {

                    if (action.payload.index == null) {
                        let taskItems = {...project.tasks};
                        Object.keys(taskItems).forEach(key => {
                            taskItems[key] = taskItems[key].filter(item => item.id !== action.payload.item.id)
                        })
                       taskItems[action.payload.type] = [...projectItem.tasks[action.payload.type], action.payload.item];
                       projectItem.tasks = taskItems;
                    } else {
                        let taskItems =  {...project.tasks};
                        taskItems[action.payload.type] = project.tasks[action.payload.type].map((item, index) => {
                            if (index === action.payload.index) {
                                item = action.payload.item
                            }

                            return item
                        })
                        console.log({ taskItems })
                        projectItem.tasks = {...taskItems};

                    }

                }
                return projectItem;
            })

            stateItem.projects = stateProjects;

           return stateItem

        case actions.DELETE_TASK:
            let stateData= {...state};
            let projectData = [...stateData.projects];

           projectData = projectData.map(project => {
                let projectItem = {...project};
                if (project.id === action.payload.projectId) {
                    let taskData = {...projectItem.tasks}
                    if (taskData[action.payload.type]) {
                        taskData[action.payload.type] = taskData[action.payload.type].filter((item, index) => index !== action.payload.index)
                        console.log({ taskData:  taskData[action.payload.type] })
                    }

                    projectItem.tasks = taskData
                }
                return projectItem
            })

            stateData.projects = projectData;

            return stateData
        default:
            return state
    }
}

export default reducer
