import './css/base.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./views/auth/loginPage";
import Register from "./views/auth/registerPage";
import LoadingComponent from "./views/loadingPage";
import Dashboard from "./views/dashboard";
import ProjectsDashboard from "./views/projects/projectsDashboard";
import TaskBoard from "./views/projects/taskBoard";
import ProjectDetails from "./views/projects/projectDetails";
import ProjectComponent from "./views/projects/projectComponent";
import ProjectData from "./views/projects/projectData";
import NewProject from "./views/projects/newProject";
import RedirectPage from "./views/redirectPage.tsx";
import MeetingComponent from "./views/meetings/MeetingComponent.tsx";
import AllMeetings from "./views/meetings/AllMeetings.tsx";
import BaseView from "./views/BaseView";
import Logout from "./views/logout";

function App() {
    const appName = 'Carbon task management'
  return (
    <BrowserRouter className={'browser-router'}>
        <Routes>
            <Route path={"/"} element={<BaseView/>}>
                <Route path={""} index element={ <LoadingComponent /> }/>
                <Route path={"login"} element={<Login app={appName}/>}/>
                <Route path={'/logout'} element={<Logout/>}/>
                <Route path={"register"} element={<Register/>}/>
                <Route path={"dashboard"} element={<Dashboard/>}/>
                <Route path={"projects"} element={<ProjectComponent/>}>
                    <Route index element={<ProjectsDashboard/> }/>
                    <Route path={"add"} element={<NewProject/>}/>
                    <Route path={"all"} index element={<ProjectsDashboard />}/>
                    <Route path={':projectId'} element={<ProjectDetails />}>
                        <Route path={''} index element={<ProjectData />}/>
                        <Route path={'taskboard'} element={<TaskBoard />}/>
                    </Route>
                </Route>
                <Route path={"appointments"} element={<MeetingComponent/>}>
                    <Route path={'all'} index element={<AllMeetings/>} />
                </Route>
                <Route path={'zoom-redirect'} element={<RedirectPage/>} />
            </Route>

        </Routes>
    </BrowserRouter>
   /* <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>*/
  );
}

export default App;
