import React, {useCallback, useEffect, useRef, useState} from "react";
import '../../css/views/login.scss'
import {Link} from "react-router-dom";
import Checkbox from "../../components/checkbox";
import { ReactComponent as Eye} from "../../assets/icons/eye.svg";
import { ReactComponent as EyeSlash } from "../../assets/icons/eye-slash.svg";
import Spinner  from "../../components/spinner";
import { setFieldBg } from "./functions/functions";
import { ReactComponent as ArrowRight } from "../../assets/icons/angle-right.svg";
import AppBanner from "../../components/appBanner";
import { useNavigate } from "react-router-dom";
import { baseApi } from "../../api/client";
import store from "../../redux/store";
import {addUser} from "../../redux/actions";
import { cloneDeep } from "lodash";

const Login = ({ app }) => {
    let navigate = useNavigate();
    let fieldElements = useRef([])

    useEffect(() => {
       if (localStorage.getItem('savedUser') != null) {
           navigate('/dashboard');
       }
        // eslint-disable-next-line
    }, [])

    const fields = [
        {
            type: "email",
            hasError: false,
            placeholder: "Your email address",
            value: '',
            errorText: ''
        },

        {
            type: "password",
            hasError: false,
            placeholder: "Your password",
            value: '',
            errorText: ''
        }
    ]

    let [passwordState, setPasswordState] = useState('password');
    let [loadingResults, setLoadingResults] = useState(false);

    let [loginFields, updateLoginFields] = useState(fields);
    let [errorMessage, setErrorMessage] = useState("");

    const removeFieldErrors = useCallback(() => {
        setErrorMessage("");
        let fields = cloneDeep(loginFields);
        fields = fields.map(field => {
            field.hasError = false;
            field.errorText = ''
            return field
        })

        updateLoginFields(fields)
    }, [updateLoginFields, loginFields])


  /* useEffect(() => {
        let fieldsWithErrorText = loginFields.filter(field => field.errorText !== '');
        console.log('Fields with error:', fieldsWithErrorText);

        if (fieldsWithErrorText.length > 0) {
            setTimeout(() => {
                removeFieldErrors();
            }, 3500)
        }
    }, [loginFields, removeFieldErrors])*/

    const handleSubmit =  (event) => {
        event.preventDefault();

        let nullValues = loginFields.filter(field => field.value === '');

        if (nullValues.length > 0) {
            updateLoginFields(cloneDeep(loginFields).map((field => {
                field.hasError = field.value === ''

                return field
            })))
        } else {
            removeFieldErrors()

            setLoadingResults(true);

            let requestData = {};

            loginFields.map(field => {
                requestData[field.type] = field.value;

                return requestData
            });

                 baseApi.post('login', requestData,{
                     headers: {
                         'Accepts': 'application/json',
                     }
                 }).then(response => {
                     setLoadingResults(false);
                    //console.log('API response:', response)
                     let userData = {
                        id: response.data.items.user.id,
                         token: response.data.items.token
                     }

                     store.dispatch(addUser(userData));
                    localStorage.setItem('savedUser', JSON.stringify(userData))
                    navigate('/dashboard')
                 }).catch(error => {
                     setLoadingResults(false);
                     if ( error.response.data.items.errors ) {


                         let errors = error.response.data.items.errors ;
                         let keys = Object.keys(errors);

                        // console.log('Errors:', errors)

                         keys.map(key => {
                             updateLoginFields(cloneDeep(loginFields).map(field => {
                                 if (field.type === key) {
                                     field.hasError = true;
                                     // console.log(errors[key])
                                     field.errorText = Object.values(errors[key])
                                 }

                                 return field
                             }));

                             return key
                         })

                         setTimeout(() => {
                             removeFieldErrors()
                         }, 2500)
                     } else {
                         setErrorMessage(`${error.code}_${error.message}`)
                     }

                 })


        }
    }



    const handleChange = (event, index) => {
        let value = event.target.value;
        let clonedFields = cloneDeep(loginFields);
        clonedFields[index] = {
            ...clonedFields[index],
            value,
            hasError:  value === ''
        }
        updateLoginFields(clonedFields)
    }
    return (
        <div className={"page-container"}>
            <div className={"login-section full-width flexbox column justify-center"}>
              <div className={'content-items centered'}>
                  <AppBanner/>
                  <div className={"form-container"}>
                    {/*  <header>Log in</header>*/}

                      <form id={"loginForm"} onSubmit={(event) => handleSubmit(event)}>
                          {
                              loginFields.map((field, index) => {
                                  return (
                                      <div className={`input-control relative-item`} key={index}>
                                          {
                                              field.hasError && field.errorText !== '' ?
                                                  <div className={'tooltip above error'}>
                                                      <div className={'content'}>{ field.errorText }</div>
                                                  </div> : null
                                          }
                                          <input
                                              className={`${field.hasError ? 'input-error' : ''}`}
                                              ref={ element => fieldElements.current[index] = element }
                                              type={field.type === 'password' ? passwordState : field.type}
                                              value={field.value}
                                              id={field.type}
                                              onChange={event => handleChange(event, index)}
                                              onBlur={() => {
                                                  setFieldBg(fieldElements.current[index], fieldElements.current[index].value)
                                              }}
                                              placeholder={field.placeholder}
                                          />


                                          {
                                              field.type === 'password' ?   <div
                                                  className={"absolute-item absolute-center-vertical eye-icon"}
                                                  onClick={() => {
                                                      let type = passwordState === 'password' ? 'text' : 'password';
                                                      setPasswordState(type);
                                                  }}>
                                                  { passwordState === 'password' ?  <Eye/> : null}
                                                  { passwordState === 'text' ? <EyeSlash /> : null}
                                              </div> : null
                                          }
                                      </div>
                                  )
                              })
                          }


                          <div className={"margin-top-xs flexbox align-center remember-user"}>
                              <Checkbox onCheck={(checked) => { console.log('Checked:', checked) }}/>
                              <span className={"font-sm margin-left-xs"}>Remember me</span>
                          </div>

                          <div className={"button-container"}>
                              <button type={"submit"} className={"button button-regular flexbox justify-center full-width align-center"}>
                                  {!loadingResults ? <div><span>Log in</span> <ArrowRight /></div>  : <Spinner size={16} color={'#FFFFFF'} thickness={1}/> }
                              </button>
                              <div className={"text-center margin-top-xs"}>
                                  <Link to={'/register'} className={"link-item"}>Don't have an account ? Sign up</Link>
                              </div>
                          </div>
                      </form>

                      {
                          errorMessage !== "" ? <div className={'error-section'}>
                              { errorMessage }
                          </div> : <></>
                      }

                  </div>
              </div>
            </div>
        </div>
    )
}

export default Login
