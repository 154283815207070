import * as React from "react";
import { apiClient } from "../../api/client";
import {useEffect} from "react";


const AllMeetings = () => {

    useEffect(() => {

        apiClient.get('generate-token', {}).then(response => {
            let header = {
                'X-CSRF-TOKEN' : response.data.token
            };
            console.log('Token:', response.data.token );
            apiClient.post('login', { "_token": response.data.token}, {
                headers: header
            }).then(postResponse => {
                console.log('Post response:', postResponse)
            })
        });
    }, [])

    return (
        <>
            <div style={{color: '#ffffff'}}>All meetings</div>
        </>
    )
}

export default AllMeetings