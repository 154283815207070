import * as actions from './actionTypes'

export const saveUser = (userData) => {
    return {
        type: actions.SAVE_USER,
        payload: {
            user: userData
        }
    }
}

export const createTimeline = (timeline) => {
    return {
        type: actions.TIMELINE_CREATED,
        payload: {
            timeline
        }
    }
}

export const createProjects = (projects) => {
    return {
        type: actions.PROJECTS_CREATED,
        payload: {
            projects
        }
    }
}

export const createMeeting = (meetingData) => {
    return {
        type: actions.CREATE_MEETING,
        payload: {
            data: meetingData
        }
    }
}

export const getTasks = (projectId) => {
    return {
        type: actions.FETCH_TASKS,
        payload: {
            id: projectId
        }
    }
}

export const updateTaskOrder = (id, tasks) => {

    return {
        type: actions.UPDATE_TASK_ORDER,
        payload: {
            id,
            tasks
        }
    }
}

export const updateProject = (projectObject) => {
   return {
        type: actions.UPDATE_PROJECT,
        payload: {
            project: projectObject
        }
    }
}

export const deleteProject = (projectId) => {
    return {
        type: actions.DELETE_PROJECT,
        payload: {
            id: projectId
        }
    }
}

export const addTask = (taskType, taskData, projectId) => {
    return {
        type: actions.ADD_TASK,
        payload: {
            type: taskType,
            data: taskData,
            id: projectId
        }
    }
}


export const createProject = projectData => {
    return {
        type: actions.CREATE_PROJECT,
        payload: {
            data: projectData
        }
    }
}

export const addUser = (userData) => {
    return {
        type: actions.ADD_USER ,
        payload: {
            data: userData
        }
    }
}

export const addMember = (member) => {
    return {
        type: actions.ADD_MEMBER,
        payload: {
            member
        }
    }
}

export const clearProjects = () => {
    return {
        type: actions.CLEAR_PROJECTS,
        payload: {}
    }
}

export const clearMembers = () => {
    return {
        type: actions.CLEAR_MEMBERS,
        payload: {}
    }
}

export const updateTaskList = (id, type, item, index = null) => {
    return {
        type: actions.UPDATE_TASK_LIST,
        payload: {
            id,
            type,
            item,
            index
        }
    }
}

export const deleteTaskItem = (projectId, type, index) => {
    return {
        type: actions.DELETE_TASK,
        payload: {
            projectId,
            type,
            index
        }
    }
}
