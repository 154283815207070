import React, {useState, useEffect} from "react";
import '../css/views/dashboard.scss';
import store from "../redux/store";
import { limitString } from "../helpers/helperFunctions";
import StatCircle from "../components/statCircle.tsx";
import StatCounter from "../components/statCounter.tsx";
import ErrorComponent from "../components/ErrorComponent";
import {getUserData} from "../helpers/apiMethods.ts";
import LoadingComponent from "../components/LoadingComponent.tsx";

const Dashboard = () => {
    const [timeOfDay, setTimeOfDay] = useState("");
    const [user] = useState({...store.getState().user, name: ""});
    const [loadingData, setLoadingData] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [taskData, setTaskData] = useState( {
        completedTasks: 0,
        activeProjects: 0,
        newAssignments: 0
    })

    let stats = [
        {
            label: 'Task Completion',
            value: 0,
            percentage: true
        },
        {
            label: 'Active projects',
            value: 0,
            percentage: false,
            valuePercentage: 100
        },

        {
            label: 'New assignments',
            value: 0,
            percentage: false,
            valuePercentage: 100
        },

        /* {
             label: 'Meeting Attendance',
             value: 100,
             percentage: true
         }*/
    ]

    let countData = [
        {
            value: 0,
            valueTag: "",
            position: "after",
            label: "Total projects"
        },

        {
            value: 0,
            valueTag: "days",
            position: "after",
            label: "Since start of last project."
        }
    ]

    const [countItems, setCountItems] = useState(countData);
    const [statItems, setStatItems] = useState(stats);
    const [userActivity, setUserActivity] = useState([]);

    function createDashboard() {
        setLoadingError(false);
        setLoadingData(true);
       setTimeout(() => {
           getUserData().then(response => {
                let data = response.data;

               setLoadingData(false)
              console.log({ data })

               if (data.projects.length > 0) {
                   let statObject = {
                       totalProjects: data.projects.length
                   }

                   let dateDifference = (new Date() - new Date(data.projects[0].created_at)) / (1000 * 60 * 60 * 24);
                   statObject['daysSinceLastProject'] = Math.round(dateDifference);


                   let countState = [...countItems];
                   countState[0].value = statObject.totalProjects;
                   countState[1].value = statObject.daysSinceLastProject;

                   countState[1].valueTag = `${statObject.daysSinceLastProject === 1 ? 'day' : 'days'}`

                   setCountItems([...countState]);

                   if (data.projects) {

                   }
                   let totalTasks = data.projects.map(project => project.tasks.length).reduce((prev, current) => {
                       return prev + current
                   }, 0)

                   let taskStats = {...taskData}

                   data.projects.forEach(project => {
                       if (project.active) {
                           taskStats['activeProjects'] += 1
                       }
                       project.tasks.forEach(task => {
                           if (task.status === 'done') {
                               taskStats['completedTasks'] += 1
                           }

                           let dayDifference = Math.round((new Date() - new Date(task.created_at)) / (1000 * 60 * 60 * 24));

                           if (dayDifference <= 1) {
                               taskStats['newAssignments'] += 1
                           }
                       })
                   })

                   taskStats['completedTasks'] = Math.round((taskStats['completedTasks'] / totalTasks) * 100);

                   let statState = [...statItems];
                   statState[0].value = taskStats.completedTasks;
                   statState[1].value = taskStats.activeProjects;
                   statState[2].value = taskStats.newAssignments;

                   setStatItems([...statState]);
                   setTaskData({...taskStats});
               }

               if (data.activity.length > 0) {
                   let timeline = [];
                   data.activity.forEach(activity => {
                       let dateItem = new Date(activity.created_at).toDateString().slice(0, 10);
                       if (timeline.indexOf(dateItem) === -1) {
                           timeline.push(dateItem)
                       }
                   })

                 let activityData = timeline.map(time => {
                       return {
                           date: time,
                           activity: data.activity.filter(activity => new Date(activity.created_at).toDateString().slice(0, 10) === time)
                       }
                   })

                   setUserActivity([...activityData]);

                   //console.log({ activityData })
               }


           })/*.catch(error => {
               setLoadingError(true);
               setLoadingData(false)
               console.log({ error })
           })*/
       }, 1000)
    }

    useEffect(() => {
       createDashboard();

       //eslint-disable-next-line
    }, [])

    useEffect(() => {
        const date = new Date();
        let hours = date.getHours();

        let time = hours < 11 ? "morning" : (hours > 11  && hours < 16) ? "afternoon" : "evening";
        setTimeOfDay(time)
    }, [setTimeOfDay]);



    return (
                <div className={'content-container'}>
                  {/*  <ActionPanel/>*/}

                    {
                      loadingData ? <LoadingComponent text={'Creating dashboard'} /> : <>
                          {
                              loadingError ?<div className={'full-width full-height flexbox justify-center align-center'}>
                                      <ErrorComponent error={'Error loading dashboard'} onRefresh={() => createDashboard()}/>
                                  </div>  :
                                  <>
                                      <section className={'main-panel'}>
                                          <header>Account Stats</header>
                                          <div className={'flexbox stat-content'}>
                                              <div className={'stat-data'}>
                                                  <StatItems items={statItems}/>
                                              </div>

                                              <div className={'stat-count-items'}>
                                                  <CountItems items={countItems}/>
                                              </div>
                                          </div>

                                      </section>

                                      <section className={'recent-activity'}>
                                          <div className={'greeting'}>Good {timeOfDay}, { user.name ? user.name : ""}</div>
                                          <>
                                              {
                                                  userActivity.length > 0 ? <>
                                                      <header className={'main-heading'}>Here's your recent recorded activity:</header>

                                                      <ul className={'activity-content'}>
                                                          {
                                                              userActivity.map((event, index) => {
                                                                  let { date, activity } = event;

                                                                  return(
                                                                      <li key={index}>
                                                                          <header className={'activity-date'}>{ date }</header>

                                                                          <ul className={'timeline-content'}>
                                                                              {
                                                                                  activity.map((item, activityIndex) => {
                                                                                      /*let assignedUser = item.assignee && item.assignee === user.name ? 'yourself' : item.assignee;
                                                                                      let taskText = item.assignee && item.assignee === user.name ? `This task was assigned to you by ${item.assigner}`
                                                                                          : `You assigned this task to ${assignedUser}`*/
                                                                                      return (
                                                                                          <li key={activityIndex} className={'activity-data'}>
                                                                                              <div className={'activity-pointer'}>
                                                                                                  <div className={'line-item'}/>
                                                                                                  <div className={`circle-item ${item.type}-item`}/>
                                                                                                  <div className={'line-item'}/>
                                                                                              </div>

                                                                                              <div className={'activity-content'}>
                                                                                                  <div className={'flexbox space-between align-center'}>
                                                                                                      <h4>{ item.title }</h4>
                                                                                                      <span>{ new Date(item.created_at).toTimeString().slice(0, 5) }</span>
                                                                                                  </div>

                                                                                                  <p className={'task-desc'}>
                                                                                                      { limitString(item.description, 30) } { item.description.split(" ").length > 30 ? '...' : ''}
                                                                                                  </p>

                                                                                                  {
                                                                                                      /*item.type === 'task' ? <div className={'task-assignment'}>{ taskText }</div> : null*/
                                                                                                  }



                                                                                              </div>
                                                                                          </li>
                                                                                      )
                                                                                  })
                                                                              }
                                                                          </ul>
                                                                      </li>
                                                                  )
                                                              })
                                                          }
                                                      </ul>
                                                  </> : <div className={'no-activity-data'}> You don't have any recent activity yet </div>
                                              }
                                          </>
                                      </section>
                                  </>
                          }
                      </>
                    }
                </div>

    )
}

const StatItems = ({ items }) => {
    return items.map((stat, index) => {
        let {label, value, percentage, valuePercentage} = stat;

        return <div className={'stat-item'} key={index}>
            <StatCircle value={value} percentage={percentage} thickness={7}
                        valuePercent={valuePercentage ? valuePercentage : null}/>
            <div className={'stat-text'}>{label}</div>
        </div>
    })
}

const CountItems = ({ items }) => {

    return items.map((data, index) => {
        let { value, valueTag, position, label } = data
        return <div className={'data-content'} key={index}>
            <StatCounter value={value} valueTag={valueTag} position={position}/>
            <div className={'stat-label'}>{ label }</div>
        </div>
    })
}


export default Dashboard;
