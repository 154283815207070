import React, { useState } from "react";
import '../css/components/checkbox.scss';
import { ReactComponent as Checkmark} from "../assets/icons/checkmark.svg";

const Checkbox = (props) => {
    let [ checked, setChecked ] = useState(false)
    return (
        <div className={"checkbox-container"}>
            <input type={"checkbox"}
                   id={"checkbox"}
                   onChange={(event) => {
                       setChecked(event.target.checked)
                       props.onCheck(event.target.checked)
                   }}
            />
            <label htmlFor={"checkbox"} className={"checkbox-item"}>
                {
                    checked ? <Checkmark /> : null
                }
            </label>
        </div>
    )
}

export default Checkbox