import * as React from "react";
import ImagePlaceholder from "./ImagePlaceholder.tsx";
import "../css/components/space-image.scss"

interface propTypes {
    src?: string
}
const SpaceImage:React.FC<propTypes> = ({ src}) => {
   return <div className={'space-image'}>
       { src && src !== '' ? <img className={'user-image'} src={src} alt={'User profile display'}/> : <ImagePlaceholder/>}
        {/*<span className={`item-status ${active ? 'active': 'inactive'}`}/>*/}
    </div>
}

export default SpaceImage
