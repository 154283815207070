import React, { useState, useEffect } from 'react';
import '../../css/views/projects/projects-dashboard.scss';
import {Link } from "react-router-dom";
import { BsPlusCircleDotted } from 'react-icons/bs';
import { FaProjectDiagram } from 'react-icons/fa';
import { MdDashboard } from 'react-icons/md';
import store from "../../redux/store";
import { clearProjects } from "../../redux/actions";
import {getUserProjects, setAndDispatchProjects} from "../../helpers/apiMethods.ts";
import LoadingComponent from "../../components/LoadingComponent.tsx";
import ErrorComponent from "../../components/ErrorComponent";



const ProjectsDashboard = () => {


    const [projectData, setProjectData] = useState([]);
    const [loadingProjects, setLoadingProjects] = useState(true);
    const [loadError, setLoadError] = useState(false);

    function getProjects() {

        let user = store.getState().user;
        setLoadError(false);
        setLoadingProjects(false);

        //  console.log({ store: store.getState() })

        let storeProjects = store.getState().projects;
       // console.log({ storeProjects})
       if (storeProjects.length > 0) {
           setProjectData([...storeProjects])
       } else {
           setLoadingProjects(true)

           getUserProjects(user.id, user.token).then(response => {
              // console.log({ response })
               store.dispatch(clearProjects())
               setAndDispatchProjects(response.data);

               let storedProjects = store.getState().projects;
               setProjectData([...storedProjects]);
             setLoadingProjects(false);

           }).catch(error => {
             //  console.log({ error })
               setLoadingProjects(false);
               setLoadError(true);
           })
       }
    }

    useEffect(() => {
      /*  if (store.getState()['projects'].length === 0) {
            store.dispatch(createProjects(dataFormat));
        }*/
        setTimeout(() => {
                getProjects();
            }, 1500)



       // setProjectData(projectItems)
    }, [])
    return (
                <div className={'content-container'}>
                    {
                        loadingProjects ? <LoadingComponent text={'Fetching projects'}/> : <>
                            {
                                loadError ? <ErrorComponent error={'Error fetching projects'} onRefresh={() => getProjects()}/> :
                                    <>
                                        <header className={'project-header flexbox'}>
                                            <h3>Here's all your current projects:</h3>

                                            <Link to={'/projects/add'} className={'flexbox align-center'}>
                                                <BsPlusCircleDotted/>
                                                <span>Create project</span>
                                            </Link>
                                        </header>

                                        <div className={'project-listing'}>
                                            {
                                                projectData.length > 0 ? projectData.map((project, index) => {
                                                    let projectClassList = project.data.active ? 'active' : 'disabled';
                                                    return (
                                                        <div key={ index } className={'project-item flexbox align-center'}>
                                                            <div className={`project-icon ${!project.data.active ? "disabled": ""}`}>
                                                                <FaProjectDiagram />
                                                            </div>

                                                            <div className={'project-content'}>
                                                                <Link to={`/projects/${project.project_id}`} className={'project-link'}>{ project.title }</Link>

                                                                <div className={'content-data'}>
                                                                    <span><b>Start date:</b> { project.data.start_date }</span>
                                                                    <span><b>Budget:</b> ${ project.data.budget }</span>
                                                                   {/* <span><b>Assignees:</b> { project.data.assignees }</span>*/}
                                                                </div>

                                                                <div className={'flexbox align-center lower-section'}>
                                                                    {/*<Link className={'edit-link'} to={`/projects/${project.id}/edit`}>
                                                    <BsFillPencilFill />
                                                    <span>Edit project</span>
                                                </Link>*/}

                                                                    <div className={'project-status'}>
                                                                        <span className={`circle ${ projectClassList }`}/>
                                                                        <span className={`status-text ${ projectClassList }`}>{ project.data.active ? 'Active': 'Inactive' }</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <Link className={"taskboard-link flexbox column align-center"} to={`/projects/${project.project_id}/taskboard`}>
                                                                <MdDashboard />

                                                                <div className={'text-center'}>Task board</div>
                                                            </Link>
                                                        </div>
                                                    )
                                                }) : <div className={'text-center no-projects'}>You don't have any projects created or assigned to you yet.</div>
                                            }
                                        </div>
                                    </>
                            }
                        </>
                    }
                </div>

    )
}

export default ProjectsDashboard
