import React, {useState} from "react";
import '../css/components/app-banner.scss';
import BannerSlider from "./bannerSlider";


const AppBanner = () => {
    let appNameItems = [
        {
            title: 'Control',
            color: '#02a5ce'
        },

       /* {
            title: 'Task',
            color: '#3f9aa8'
        },*/

        {
            title: 'Forte',
            color: '#008ba0'
        }
    ]

    let sliderText = [
        "Create and manage projects",
        "Assign tasks",
        "Create and schedule meetings"
    ]

    const [bannerItems] = useState(appNameItems)
    return (
        <>
            <div className={'app-name'}>
                {
                    bannerItems.map((item, index) => {
                        return (
                            <span key={index} className={'banner-item'} style={{color: item.color}}>{ item.title }</span>
                        )
                    })
                }
            </div>

            <BannerSlider items={sliderText}/>
        </>
    )
}

export default AppBanner
