import * as React from "react";
import Spinner from "./spinner";
import "../css/components/loading-component.scss";

interface propTypes {
    text: string
}
const LoadingComponent:React.FC<propTypes> = ({ text }) => {
    return  <div className={'full-width full-height flexbox justify-center align-center'}>
        <div className={'loading-text'}>{ text }</div>
        <Spinner size={16} color={'#f2f2f2'} thickness={2}/>
    </div>
}

export default LoadingComponent;
