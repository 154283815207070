import * as React from "react";
import Navigation from "../../components/navigation";
import SidePanel from "../../components/sidePanel";
import {Outlet} from "react-router-dom";

const MeetingComponent = () => {
    return (
        <section className={'page-container'}>
            <Navigation/>
            <SidePanel/>

            <section className={'main-content'}>
                <Outlet/>
            </section>
        </section>
    )
}

export default MeetingComponent;