export const TIMELINE_CREATED = "createTimeline";
export const SAVE_USER = "saveUser";
export const TASK_CREATED = "createTask";
export const PROJECTS_CREATED = "projectsCreated";
export const CREATE_MEETING = "createMeeting";
export const FETCH_TASKS = "getTasks";
export const UPDATE_TASK_ORDER = "updateTaskOrder";
export const UPDATE_PROJECT = "updateProject";
export const DELETE_PROJECT = "deleteProject";
export const ADD_TASK = "addTask";
export const CREATE_PROJECT = "createProject";
export const ADD_USER = "addUser";
export const ADD_MEMBER = "addMember";
export const CLEAR_PROJECTS = "clearProjects";
export const CLEAR_MEMBERS = "clearMembers";
export const UPDATE_TASK_LIST = "updateTaskList";
export const DELETE_TASK = "deleteTask";
