import React, {useEffect, useState} from "react";
import Navigation from "../components/navigation";
import SidePanel from "../components/sidePanel";
import {Outlet} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import store from "../redux/store";
import {addUser} from "../redux/actions";

const BaseView = () => {
    let navigate = useNavigate();
    const [showPanel, setShowPanel] = useState(false);
    const [storeUser, setStoreUser] = useState({});
    useEffect(() => {
     // localStorage.removeItem('savedUser');
        let storedUser = localStorage.getItem('savedUser');
        if (storedUser == null)  {
          navigate('/login');
      }  else {
          let userData = JSON.parse(storedUser);
          store.dispatch(addUser(userData))
      }

        setStoreUser(store.getState().user);

       store.subscribe(() => {
            if (!("id" in store.getState().user) || store.getState().user.id !== storeUser.id) {
                setStoreUser(store.getState().user)
            }
        })

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.addEventListener("resize", function () {
                if (this.innerWidth > 990) {
                    setShowPanel(false)
                }

        })

        function handleClick(e) {
                if (showPanel) {

                    if (e.target.closest(".side-panel") == null && e.target.closest(".menu-icon") == null){
                        setShowPanel(false)
                    }
                }
        }
        document.removeEventListener('click', handleClick)
        document.addEventListener("click", handleClick)
    }, [showPanel])

    return  <section className={`page-container ${showPanel ? 'overflow-hidden' : ''}`}>
        {
            "id" in store.getState().user ? <>
                <Navigation onToggle={() => setShowPanel(!showPanel)}/>

                <SidePanel open={showPanel} onClose={() => setShowPanel(false)}/>
            </>: null
        }

        <section className={`main-content ${showPanel ? 'faded' : ''} ${ "id" in storeUser ? 'authenticated' : 'unauthenticated'}`}>
            <Outlet/>
        </section>
    </section>
}

export default BaseView
