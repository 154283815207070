import React, {useEffect, useRef, useState} from "react";
import "../css/components/suggestions.scss";
import {BiSearchAlt} from "react-icons/bi";
import Spinner from "./spinner";
import debounce from "lodash.debounce";
import produce from "immer";

const Suggestions = (props) => {
    let data = props.data;
    const [items, updateItems] = useState([]);
    const [searching, setSearching] = useState(false);
    const [showNoResults, updateShowNoResults] = useState(false);


    document.addEventListener("click", function (e) {
        if (e.target.closest(".suggestion-list") == null) {
            updateItems([...[]]);
            updateShowNoResults(false)
        }
    })

    const searchForMatches = debounce((value) => {
        setSearching( false);
        let searchValues = data.filter(item => item.label.toLowerCase().includes(value.toLowerCase()));

        updateItems(produce(searchValues, draft => draft));
    }, 1000);
    let searchInput = useRef(null);

    useEffect(() => {
        if (searchInput.current != null && searchInput.current.value !== '' && !searching) {
            updateShowNoResults( true)
        } else {
            updateShowNoResults(false)
        }
    }, [searching])

    let defaultValue = props.defaultValue;

    useEffect(() => {
        searchInput.current.value = defaultValue == null ? '' : defaultValue;
      //  console.log('Default value changed to:', searchInput.current.value);

    }, [defaultValue])



    return (
        <div className={'suggestion'}>
            <div className={'input-control'}>
                <BiSearchAlt className={'icon search-icon'} />
                <input type={'text'}
                       onChange={event => {
                           setSearching(true);
                           searchForMatches(event.target.value);
                       }}
                       ref={element => searchInput.current = element}
                       name={'search'}
                       placeholder={`${props.placeholder ? props.placeholder : 'Search'}`}/>
                {
                    searching ? <div className={'icon spinner-icon'}>
                                    <Spinner color={'#f2f2f2'} size={14} thickness={1}/>
                    </div> : <></>
                }
            </div>

            {
                items.length > 0 && searchInput.current.value !== '' ?  <ul className={'suggestion-list'}>
                    {
                        items.map((item, index) => {
                            return (
                                <li className={'data-item'}
                                    key={index}
                                    onClick={event => {
                                        event.stopPropagation();
                                        searchInput.current.value = item.label
                                        updateItems([...[]])
                                        updateShowNoResults(false)
                                        props.onSelect(item.label)
                                    }}
                                >
                                    { item.label }
                                </li>
                            )
                        })
                    }
                </ul> :
                  showNoResults ?
                        <div className={'no-results'}>No results</div> : <></>

            }

        </div>
    )
}

export default Suggestions
