import React, { useState } from "react";
import '../css/components/side-panel.scss';
import {Link, NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as House} from "../assets/icons/house.svg";
import { ReactComponent as SignOut } from "../assets/icons/sign-out.svg";

const SidePanel = ({ open, onClose }) => {
    let navigate = useNavigate();
    let initSiteMap = [
        {
            mainLink: 'Application',
            children: [
                {
                    label: 'Dashboard',
                    link: '/dashboard',
                    icon: <House />
                }
            ]
        },

        {
            mainLink: 'Projects',
            children: [
                {
                    label: 'Recent projects',
                    link: '/projects',
                   /* grandChildren: [
                        {
                            label: 'Test project 1',
                            link: '/projects/1232142'
                        },

                        {
                            label: 'Test project 2',
                            link: '/projects/1092032'
                        }
                    ]*/
                },

                {
                    label: 'Create project',
                    link: '/projects/add'
                },

                {
                    label: 'View all',
                    link: '/projects/all'
                }
            ]
        }
        /*{
            mainLink: 'Meetings',
            children: [
                {
                    label: 'Create meeting',
                    link: '/appointments/new'
                },

                {
                    label: 'Upcoming meetings',
                    link: '/appointments/all'
                }
            ]
        }*/
    ];

    const navigateApp = (e, link) => {
        e.preventDefault();
        onClose()
        navigate(link)

    }

    const [sitemap] = useState(initSiteMap);

    return (
        <div className={`side-panel ${open ? 'mobile-open' : 'mobile-closed'}`}>
            <div className={'sidebar-items'}>
                {
                    sitemap.map((item, index) => {
                        let { mainLink, children } = item;
                        return (
                            <div className={'link-section'} key={index}>
                                <div className={'item-main-heading'}>{ mainLink }</div>
                                    <ul className={'linklist childlist'}>
                                        {
                                            children.map((child, childIndex) => {
                                                let { label, link, grandChildren } = child;
                                                return (
                                                    <li key={`${label}-%${childIndex}`} className={'childlist-item'}>
                                                        <NavLink to={ link } onClick={e => navigateApp(e, link)}>{child.icon ? child.icon : ''} { label }</NavLink>

                                                        {
                                                            grandChildren ? <ul className={'linklist grandChildList'}>
                                                                {
                                                                    grandChildren.map((grandChild, grandChildIndex) => {
                                                                        let {label, link} = grandChild
                                                                            return (
                                                                                <li key={`${label}-${grandChildIndex}`}>
                                                                                    <NavLink to={ link }>{ label }</NavLink>
                                                                                </li>
                                                                            )
                                                                    })
                                                                }
                                                            </ul>  : null
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                            </div>
                        )
                    })
                }

                <div className={'link-section sign-out-section'}>
                    <SignOut />
                    <Link to={'/logout'} onClick={e => navigateApp(e, '/logout')}>Sign out</Link>
                </div>
            </div>
        </div>
    )
}

export default SidePanel;
