import React from "react";
import { MdNearbyError } from "react-icons/md";
import styles from '../css/components/error.module.scss';
import {FiRefreshCcw} from "react-icons/fi";


const ErrorComponent = ({ error, onRefresh }) => {
    return (
        <div className={ styles.error_container }>
            <MdNearbyError className={ styles.icon }/>
            <div className={ styles.text }>{ error }</div>
            <FiRefreshCcw className={styles.refresh} onClick={() => onRefresh()}/>
        </div>
    )
}

export default ErrorComponent;
