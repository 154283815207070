import React, {useCallback, useEffect, useRef, useState} from "react";
import  "../css/components/dropdown.scss";
import { IoIosArrowDown } from "react-icons/io";
import { BiSearchAlt } from "react-icons/bi";
import debounce from "lodash.debounce";

const DropdownComponent = (props) => {
    let data = props.data;
  //  console.log(data);

    useEffect(() => {
        updateDataItems(data)
    }, [data])
    let defaultIndex = props.default;
    let [dataItems, updateDataItems] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(defaultIndex ? defaultIndex : 0);
    const [listOpen , setListOpen] = useState(false);

    document.addEventListener("click", function (e) {
        if (e.target.closest(".dropdown-container") == null) {
            setListOpen(false);
        }

    })

    const filterResults = debounce((value) => {
        let filteredResults = data.filter(data => data.label.toLowerCase().includes(value.toLowerCase()));

        updateDataItems([...filteredResults]);
    }, 500)


    useEffect(() => {
       if (!listOpen) {
           updateDataItems([...data]);
       }
    }, [listOpen])

    const changeValue = (event, index) => {
        event.stopPropagation();

        let selectedData = dataItems[index];
        props.onSelect(selectedData);
        let dataIndex = data.indexOf(selectedData);

        setSelectedIndex(dataIndex);

       setListOpen(false);
    }

    const toggleState = event => {
        event.stopPropagation();

        let newState = !listOpen;
        setListOpen(newState)
    }

    return (
        <div className={'dropdown-container'}>
            <div className={'select'} onClick={ event => toggleState(event) }>
                <div>{ data[selectedIndex] ? data[selectedIndex].label  : ''}</div>

                <IoIosArrowDown className={`toggle ${listOpen ? 'open' : 'closed'}`} />
            </div>

            {
                listOpen ? <div className={'dropdown'}>
                    <div className={'search-holder'}>
                        <div className={'input-control'}>
                            <BiSearchAlt className={'search-icon'} />
                            <input type={'text'} placeholder={'Search..'} onChange={event => filterResults(event.target.value)}/>
                        </div>
                    </div>

                    {
                        dataItems.length > 0 ? <ul className={'dropdown-list'}>
                            {
                                dataItems.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={'data-item'}
                                            onClick={event => changeValue(event, index)}
                                        >
                                            { item.label }
                                        </li>
                                    )
                                })
                            }
                        </ul> : <div className={'no-results'}>No results.</div>
                    }
                </div> : <></>
            }
        </div>
    )



}

export default DropdownComponent;