import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/spinner";

const LoadingComponent = () => {
    let navigate = useNavigate();
    useEffect(() => {
       setTimeout(() => {
           if (localStorage.getItem("savedUser") == null) {
               navigate('/login')
           } else {
               navigate('/dashboard')
           }
       }, 1000)
    }, [navigate]);

    return (
        <div style={{height: '100vh'}} className={"flexbox align-center justify-center full-width"}>
            <Spinner size={32} color={"rgb(7, 89, 155)"} thickness={3}/>
        </div>
    )
}

export default LoadingComponent;