import React, {useState} from "react";
import styles from '../css/components/switch.module.scss';

const Switch = (props) => {
    let checked = props.checked
    const [state, updateState] = useState(checked ? checked : false);
    return (
        <>
           <input
               className={styles.checkbox}
               type={'checkbox'}
               id={'checkbox-item'}
               checked={state}
               onChange={event => {
                   updateState(event.target.checked);
                   props.stateChange(event.target.checked)
               }}
           />
            <label className={styles.slider} htmlFor={'checkbox-item'}>
                <span className={styles.switch}/>
            </label>
        </>
    )
}

export default Switch;