import React from 'react';
import {Outlet} from "react-router-dom";


const ProjectComponent = () => {

        return (
           <section className={'project-component'}>
               <Outlet/>
           </section>
        )
}

export default ProjectComponent
