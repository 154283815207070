import React, {useEffect, useRef, useState} from "react";
import {MdOutlineClose} from "react-icons/md";
import {abbreviateName, splitString} from "../helpers/helperFunctions";
import Suggestions from "./suggestions";
import store from "../redux/store";
import DatePicker from "react-datepicker";
import DropdownComponent from "./DropdownComponent";
import Spinner from "./spinner";
import { cloneDeep } from "lodash";


const AddBoardModal = (props) => {
    let fieldItems = [{ label: 'Task name', key: 'title'}, { label: 'Description', key: 'description'}, { label: 'Assignees', key: 'assignees'}];

    let dropdown = props.taskTypes.map(type => { return { value: type, label: splitString(type) }});
    let taskTypes = props.taskTypes, dataType = props.type;

    if (dataType === 'inProgress') {
        fieldItems.splice(1, 0, {label: 'Due date', key: 'dueBy'});
    }

    let fields = fieldItems.map(field => {
        let { label, key } = field
        let objectTemplate = {
            name: label,
            placeholder: `Add ${label.toLowerCase()}`,
            label,
            hasError: false,
            value: "",
            key
        }


        let fieldName = label.toLowerCase();


        objectTemplate.type = label.includes('date') ? 'date' : fieldName === 'assignees' ? 'array' : "text";
           objectTemplate.multiLine = fieldName === 'description';
           objectTemplate.fullWidth = fieldName === 'description' || fieldName === 'assignees';


           objectTemplate.value = label.includes('date') ? new Date().toISOString().slice(0, 10) : "";

           if (objectTemplate.type === 'array') {
               objectTemplate.value = new Array(0);
           }

           return objectTemplate
    });



    const [formFields, updateFormFields] = useState(fields);
    const [defaultValue, updateDefaultValue] = useState("");
    const [taskStatus , setTaskStatus] = useState(dataType);
    const [creatingTask, setCreatingTask] = useState(false);

    useEffect(() => {
        if (creatingTask) {
            let taskData = {
                data: {},
                type: taskStatus
            }
            formFields.map(field => {
                taskData.data[field.key] = field.value

                return field
            })

            setTimeout(() => {
                props.onCreate(taskData)
                setCreatingTask(false)
            }, 1000)
        }
    }, [creatingTask, formFields, props, taskStatus])

    const setFieldValue = (value, index) => {
        let fields = cloneDeep(formFields);
        fields[index] = {
            ...fields[index],
            value,
            hasError: value === ''
        }
        updateFormFields(fields)
    }

    useEffect(() => {
      if (defaultValue == null) {
          updateDefaultValue('');
      }
    }, [defaultValue])

    let users = store.getState().users.map(user => { return { value: `${user.name.split(" ")[0]} ${user.name.split(" ")[1][0]}.`, label: user.name }});
    let currentUser = useRef("");

   /* useEffect(() => {
        console.log('Form fields changed to:', formFields)
    }, [formFields])*/

    const addUser = (event, index) => {
        event.stopPropagation();
        event.preventDefault();
        let user = currentUser.current.slice();

        if (user !== '' && formFields[index].value.indexOf(user) === -1) {
            updateDefaultValue(null);

           // console.log('Assignees:', formFields[index])

            let fields = cloneDeep(formFields);
            fields[index].value.push(currentUser.current)

            updateFormFields(fields)

            currentUser.current = "";
        }

    }

    const createTask = e => {
        e.preventDefault();
        e.stopPropagation();

        let emptyFields = formFields.filter(field => getFieldCondition(field.value));

        if (emptyFields.length > 0) {
            updateFormFields(cloneDeep(formFields).map(field => {
                field.hasError = getFieldCondition(field.value);
                return field
            }))
        } else {
            setCreatingTask(true)
        }

        //console.log('Task about to be created', emptyFields.length);

        function getFieldCondition(field) {
            return (Array.isArray(field) && field.length === 0) || field === ''
        }

    }


    return (
        <div className={'modal add-board-modal'}
             onClick={event => {
                 if (event.target.closest(".modal-content") == null) {
                     props.outsideClick()
                 }
             }}
        >
            <div className={'modal-content'}>
                <div className={'close-button'} onClick={() => props.onClose()}>
                    <MdOutlineClose className={'close-icon'} />
                </div>

                <div className={'modal-header flexbox'}>
                    <header>Create new task </header>

                   <div className={' task-status flexbox align-center'}>
                       <span>Status:</span>
                       <DropdownComponent data={dropdown} default={taskTypes.indexOf(dataType)} onSelect={data => {
                           setTaskStatus(data.value)
                       }}/>
                   </div>
                </div>


                <form method={'post'} onSubmit={e => createTask(e)}>
                    <div className={'form-item'}>
                        {
                            formFields.map((item, index) => {
                                return (
                                    <div className={`input-control ${item.fullWidth ? 'full-width' : ''}`} key={index}>
                                        {
                                            item.type !== 'array' ? <>
                                                <label>{ item.label }</label>
                                                {
                                                    item.type === 'date' ? <DatePicker
                                                            selected={new Date(item.value)}
                                                            onChange={date => {
                                                               /* let newDate = new Date(date -(1000 * 60 * 60* 24));
                                                                console.log('New date:', newDate.toISOString().slice(0, 10))*/
                                                                setFieldValue(date.toISOString().slice(0, 10), index)
                                                            }}
                                                        />
                                                        : <>

                                                            {
                                                                item.multiLine ? <textarea
                                                                        placeholder={item.placeholder}
                                                                        value={item.value}
                                                                        name={item.name}
                                                                        className={`${item.hasError ? 'input-error' : ''}`}
                                                                        onChange={event => setFieldValue(event.target.value, index)}
                                                                    /> :
                                                                    <input
                                                                        placeholder={item.placeholder}
                                                                        value={item.value}
                                                                        name={item.name}
                                                                        className={`${item.hasError ? 'input-error' : ''}`}
                                                                        onChange={event => setFieldValue(event.target.value, index)}
                                                                    />
                                                            }
                                                        </>
                                                }
                                            </>:  <div>
                                                <div className={'label flexbox align-center space-between'}>
                                                    <div>{ item.label }</div>

                                                    <div className={'flexbox align-center new-user'}>
                                                        <Suggestions
                                                            placeholder={'Search users'}
                                                            defaultValue={defaultValue}
                                                            data={users}
                                                            onSelect={value => {
                                                                currentUser.current = value;
                                                            }}
                                                        />

                                                        <button
                                                            className={'button blue-alternate'}
                                                            type={'submit'}
                                                            onClick={event => addUser(event, index)}
                                                        >Add</button>
                                                    </div>
                                                </div>
                                                <div className={'assigned-to'}>
                                                    {
                                                        item.value.length > 0 ?  item.value.map((assignee, assigneeIndex) => {
                                                            return (
                                                                <div className={'user-item'} key={assigneeIndex}>
                                                                    <div className={'user-image'}>{ assignee[0] }</div>

                                                                    <div className={'user'}>{ abbreviateName(assignee) }</div>

                                                                    <MdOutlineClose
                                                                        className={'remove-assignee'}
                                                                        onClick={e => {
                                                                            e.stopPropagation();
                                                                            let fields = cloneDeep(formFields);
                                                                            fields[index].value = fields[index].value.filter((valueItem, valueIndex) => valueIndex !== assigneeIndex);
                                                                            updateFormFields(fields)
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        }) : <div className={`text-center no-users ${item.hasError ? 'input-error': ''}`}>No users assigned.</div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>


                    <div className={'button-container'}>
                        <button className={'button blue-alternate flexbox align-center justify-center'}>
                            {
                                creatingTask ? <Spinner className={'spinner-icon'} size={20} color={'#f2f2f2'} thickness={1}/> : <span>Create</span>
                            }
                        </button>
                    </div>

                </form>
            </div>
        </div>
    )
}


export default AddBoardModal;
