import * as React from "react";
import "../css/components/stat-circle.scss";
import {useCallback, useEffect, useRef, useState} from "react";

interface propTypes {
    value: number,
    thickness: number,
    percentage: boolean,
    valuePercent?: number
}

const StatCircle:React.FC<propTypes> = ({value, thickness, percentage, valuePercent}) => {

    let [statValue, setStatValue] = useState<number>(0);

    const size = 85 - thickness;

    const incrementStat =  useCallback<() => void>(() => {
        setTimeout(() => {
            const newStatValue = statValue + 1;
            let valueData = valuePercent ? valuePercent : value
            if (statValue < valueData) {
                setStatValue(newStatValue)
            }

        }, 10)
        // eslint-disable-next-line
    }, [statValue]);

    let statCircle = useRef(null);



    useEffect(() => {
        let valueData = valuePercent ? valuePercent : value
        if (statValue < valueData) {
            incrementStat()
        }
        //eslint-disable-next-line
    }, [statValue, value, valuePercent])

    let statIntersection = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                incrementStat();
                observer.unobserve(entry.target)
            }
        })
    })

    const StatElement : React.FC = () => {
        return <div ref={element => statCircle.current = element} className={'stat-circle'} style={{background: `conic-gradient(rgba(63, 154, 168, .8) ${statValue * 3.6}deg, rgba(255, 255, 255, .1) ${(statValue) * 3.6}deg)` }}>
            <div className={'circle-inner'} style={{ width: size, height: size}}>{valuePercent ? value : statValue}{percentage ? "%" : ""}</div>
        </div>
    }
    useEffect(() => {
        statIntersection.observe(statCircle.current);
        //eslint-disable-next-line
    }, [])


    return (
        <StatElement/>
    )
}

export default StatCircle
