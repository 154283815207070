export const limitString = (string, limit) => {
    return  string.split(" ").slice(0, limit).join("+").replace(/\+/g, " ")
}


export const generateRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max-min)) + min;
}

const capitalizeFirstLetter = (string) => {
    return string.toString().charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeFirstLetters = (string) => {
    return string.split(" ").map(item => {return capitalizeFirstLetter(item)}).toString().replace(/,/g, " ")
}

export const splitString = (string) => {
    let strArray = string.split("");
    strArray.map((str, index) => {
        if (str.toUpperCase() === str) {
            strArray.splice(index, 0, " ")
        }

        return str
    })

    strArray[0] = strArray[0].toUpperCase();

    return strArray.toString().replace(/,/g, "").replace(/\s+/g, " ").trim();
}

export const splitDate = dateString => {
    let dateSplit = dateString.split("-");
    return new Date(parseInt(dateSplit[0]), parseInt(dateSplit[1])-1, parseInt(dateSplit[2]))
}

export const abbreviateName = (name) => {
    let nameSplit = name.split(" ");
    return `${nameSplit[0]} ${nameSplit[1][0]}.`
}

export const addUnderscores = (string) => {
    let stringSplit = string.split("");
   stringSplit = stringSplit.map((item, index) => {
        if (item.toUpperCase() === item) {
            item = `_${item.toLowerCase()}`;
        }

        return item
    })

    return stringSplit.toString().replace(/,/g, "");
}

export const generateStringIntegerArray = (supportsFloat = false) => {
    let fields = new Array(10).fill('');
    fields = fields.map((field, index) => index.toString());

    if (supportsFloat) {
        fields.push(".");
    }

    return fields
}

export const validateCurrencyValue = (value) => {
    let lastInput = value[value.length-1], fieldValue;

    if (lastInput) {
        let fields = generateStringIntegerArray(true) ;

        fieldValue = value;

        if (fields.indexOf(lastInput) === -1) {
            console.log('Value not found')
            fieldValue = fieldValue.slice(0, fieldValue.length-1)
        }

        if (lastInput === ".") {
            let valueSplit = value.split("");
            valueSplit = valueSplit.slice(0, valueSplit.length-1);

            if (valueSplit.indexOf(lastInput) !== -1) {
                fieldValue = fieldValue.slice(0, fieldValue.length-1)
            }
        }

        //    console.log(fieldValue ? 'Field value item' + fieldValue : '');
    }

    return fieldValue ? fieldValue : ''
}

