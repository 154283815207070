import React, { useEffect, useRef } from "react";
import styles from '../css/components/banner-slider.module.scss';

const BannerSlider = ({ items }) => {
    let activeIndex = useRef(0);
    let sliderItems = useRef([]);
    let bannerSlider = useRef(null);
    let sliderContainer = useRef(null)
    useEffect(() => {
        const loopItems = () => {
            let animationValue = 100 * (activeIndex.current);
            sliderContainer.current.style.transform = `translateX(-${animationValue}%)`;


            setTimeout(() => {
                activeIndex['current'] =  activeIndex.current === items.length-1 ? 0 : activeIndex.current+1;
                setTimeout(() => loopItems(), 2000)
            }, 800)
        }

        loopItems()
    }, [items.length])
    return (
        <>
            <div className={styles.banner} ref={element => bannerSlider.current = element}>
               <div className={styles.content} ref={element => sliderContainer.current = element}>
                   {
                       items.map((item, index) => {
                           return (
                               <div ref={element => sliderItems.current[index] = element} key={index} className={styles.item}>
                                   { item }
                               </div>
                           )
                       })
                   }
               </div>
            </div>
        </>
    )
}

export default BannerSlider