import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import store from "../../redux/store";
import {addMember, clearMembers, deleteTaskItem, updateTaskList, updateTaskOrder} from "../../redux/actions";
import '../../css/views/projects/taskboard.scss';
import {abbreviateName, addUnderscores, limitString, splitString} from "../../helpers/helperFunctions";
import debounce from "lodash.debounce";
import {AiOutlinePlusCircle} from "react-icons/ai";
import BoardItemModal from "../../components/BoardItemModal";
import AddBoardModal from "../../components/addBoardModal";
import {apiClient} from "../../api/client";
import {getUserProjects, setAndDispatchProjects} from "../../helpers/apiMethods.ts";


const TaskBoard = () => {
    let params = useParams();
    let projectId =  params['projectId'];
    const [projectData, setProjectData] = useState({})


  /*  useEffect(() => {
        console.log('task board re-rendered')
    })*/

    useEffect(() => {
        setTimeout(() => {

            apiClient.get('users', {
                headers: {
                    Accepts: 'application/json',
                    Authorization:`Bearer ${store.getState().user.token}`
                }
            }).then(response => {
                store.dispatch(clearMembers())
                response.data.forEach(data => {
                    store.dispatch(addMember(data))
                })
            })/*.catch(error => {
                console.log(error)
            })*/
        }, 1000)
    }, [])

    function generateProjectType(project) {
        return {
            type: 'projectGenerated',
            tasks: project.tasks,
            id: project.id,
            projectTitle: project.title
        }
    }
    function getProjects() {
        let user = store.getState().user;

        getUserProjects(user.id, user.token).then(response => {
            setAndDispatchProjects(response.data)

            let projectData = store.getState().projects.find(project => project.project_id === projectId);

            if (projectData) {
              //  console.log( { projectData, data: response.data })
                setProjectData({...generateProjectType(projectData)})
            }

        })
    }

    useEffect(() => {
       //store.dispatch(getTasks(projectId))
        let state = store.getState();

        let project;
        if (state.projects && state.projects.length > 0) {
            project = state.projects.find(project => project.project_id === projectId)
        }

        if (project) {

         let returnState = generateProjectType(project);


           // console.log('Return state:', returnState)
            setProjectData({...returnState})
        } else{
            setTimeout(() => {
                getProjects()
            }, 1000)
        }
    //eslint-disable-next-line
    }, [projectId])

   /* useEffect(() => {
        console.log({ projectData })
    }, [projectData])*/


    const [taskTypes] = useState(['backlog', 'inProgress', 'inReview', 'done'] );

    let taskContainer = useRef(null)
    let taskLists = useRef([]);



    const debounceDrag = debounce(event => {
        taskLists.current.map((list, index) => {
            let listPosition = list.getBoundingClientRect();
            let offsetTop = ( listPosition.top - list.offsetTop );
            let withinWidth = event.clientX > listPosition.left && event.clientX < (listPosition.left + list.clientWidth);
            let withinHeight = event.clientY > offsetTop && event.clientY < (listPosition.top + list.offsetHeight);

            if (withinWidth && withinHeight) {
                list.style.transform = "translateY(20px)";
            } else {
                list.removeAttribute('style')
            }
            return list
        })
    }, 5)

    const clearDropIndicator = () => {
        setTimeout(() => {
            taskLists.current.map(list => list.removeAttribute('style'))
        }, 100)
    }

    const reOrderTasks = (draggedItem, type, dropIndex = null) => {
        //console.log('Drop index:',type, dropIndex)
        let newState = {...projectData};
        let draggedTaskContainer = newState.tasks[draggedItem.type];
        let dragItemData = {...draggedTaskContainer[draggedItem.index], animatable: true};
        dragItemData.status = type;
        let itemTasks = {...newState.tasks}
        itemTasks[draggedItem.type] = draggedTaskContainer.filter((task, index) => index !== draggedItem.index);

        newState.tasks = itemTasks

        let taskTypeData = [...newState.tasks[type]];
        let taskItems = [];
        if (type === 'inProgress') {
            dragItemData.dueBy = new Date().toISOString().slice(0, 10);
        }

        if (dropIndex == null) {
            taskTypeData.push(dragItemData)
            taskItems = taskTypeData;
        } else {
            taskTypeData.splice(dropIndex, 0, dragItemData);
            taskItems = taskTypeData
        }

        newState.tasks[type] = taskItems



        setProjectData({...newState})
        store.dispatch(updateTaskOrder(newState.id, newState.tasks));
        clearDropIndicator()


        let reqObject = {
            project_id: dragItemData.projects_id,
            task_id:  dragItemData.id,
            status: addUnderscores(dragItemData.status)
        }


     // console.log({ reqObject })
        let user = store.getState().user;

        apiClient.post('status/update', reqObject, {
            headers: {
                Accepts: 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        })/*.then(response => {
            console.log({ data: response.data })
        }).catch(error => {
            console.log({ error })
        })*/
    }

    const handleBoardItemDrop = (event) => {
        let dropItemKey =  event.target['dataset'].key;

        if (dropItemKey) {
            //console.log('Drop item key:', dropItemKey);
            let dropItem = dropItemKey.split("-");
            let dragItem = JSON.parse(event.dataTransfer.getData('boardItem'));

            //console.log(dragItem, dropItem)

           reOrderTasks(dragItem, dropItem[0], dropItem[1])
            //console.log('Cloned state:', clonedState)

           // store.dispatch(updateTaskOrder(clonedState.id, clonedState.tasks))
        }
    }

    const handleDragStart = (event, type, index) => {
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.dropEffect = 'move';

            let boardItem = {
                type,
                index
            }
            event.dataTransfer.setData('boardItem', JSON.stringify(boardItem))
    }

    const handleTaskListDrop = (event) => {
        if (event.target.classList.contains('task-list')) {
            let type = event.target['dataset'].key;



            if (type && projectData.tasks[type].length <= 1) {
                let draggedItem = JSON.parse(event.dataTransfer.getData('boardItem'));

               // console.log('Type:', type)
                reOrderTasks(draggedItem, type)
            }

        }
    }





    const saveNewState = (state) => {
      //  console.log({ state });
        let user = store.getState().user;
        let reqObject = {
            project_id: parseInt(state.data.projects_id),
            title: state.data.title,
            status: addUnderscores(state.status),
            description:  state.data.description,
            user_id: user.id,
            users: state.data.users.map(user => user.id),
            task_id: state.data.id
        }

       // console.log({ reqObject })

        apiClient.post('task/update', reqObject, {
            headers: {
                Accepts: `application/json`,
                Authorization: `Bearer ${user.token}`
            }
        }).then(response => {
            let data = response.data
           // console.log({ data })

            let activeData = {...activeItem};

            if (activeData.type !== state.status) {
                store.dispatch(updateTaskList(projectData.id, state.status, data))
            } else {
                store.dispatch(updateTaskList(projectData.id, activeData.type, data, activeData.index))
            }


            let projectInfo = store.getState().projects.find(project => project.project_id === projectId);
            if (projectInfo) {
               // console.log({ projectInfo })
                setProjectData({...generateProjectType(projectInfo)})
                setActiveItem({...{}})
            }
        })/*.catch(error => {
            console.log({ error })
        })*/

        /*let activeData = {...activeItem};
        setActiveItem({...{}})

        setProjectData(produce(projectData , draft => {
            if (activeData.type !== state.status) {
                draft.tasks[activeData.type] = draft.tasks[activeData.type].filter((task, taskIndex) => taskIndex !== activeData.index);
                draft.tasks[state.status].push(state.data)
            } else {
                draft.tasks[activeData.type][activeData.index] = state.data
            }

        }))*/

    }

    const deleteTask = () => {
        let item = {...activeItem};
       // console.log({ item });

        let dataItem = projectData.tasks[item.type][item.index];

        //console.log({ dataItem })

        let user = store.getState().user
        let reqObject = {
            task_id: dataItem.id,
            project_id: dataItem.projects_id,
            user_id: user.id
        }

        apiClient.post('task/delete', reqObject, {
            headers: {
                Accepts: 'application/json',
                Authorization: `Bearer ${user.token}`
            }
        }).then(() => {
            store.dispatch(deleteTaskItem(projectData.id, item.type, item.index));

            let projectInfo = store.getState().projects.find(project => project.id === projectData.id);

           // console.log({ projectInfo })

            if (projectInfo) {
                setProjectData({...projectInfo})
            }

            setActiveItem({});
        })

       /* store.dispatch(deleteTaskItem(projectData.id, item.type, item.index));

        let projectInfo = store.getState().projects.find(project => project.id === projectData.id);

        console.log({ projectInfo })

        if (projectInfo) {
            setProjectData({...projectInfo})
        }

        setActiveItem({});*/
       /* setActiveItem({});

        setProjectData(produce(projectData, draft => {
            draft.tasks[item.type] = draft.tasks[item.type].filter((task, taskIndex) => taskIndex !== item.index);
        }))*/

    }

    const [showItemModal, setShowItemModal] = useState(false);
    const [activeItem, setActiveItem] = useState({});

    useEffect(() => {
        const updateStore = () => {
            // console.log('Store updated', projectData.id, projectData.tasks)
            store.dispatch(updateTaskOrder(projectData.id, projectData.tasks));
            setActiveItem({})
            setShowItemModal(false);
        }

        if (showItemModal && Object.values(activeItem).length === 0) {
            updateStore()
        }


    }, [projectData, showItemModal, activeItem])


    useEffect(() => {
        if (Object.values(activeItem).length > 0) {
           // console.log('Active item set', activeItem)
            setShowItemModal( true);
        }
    }, [ activeItem ])

    function createNewTask(data) {
      //  console.log({ data })
    let user = store.getState().user;
      let reqObject = {
          status: addUnderscores(data.type),
          description: data.data.description,
          title: data.data.title,
          user_id: user.id,
          project_id: projectData.id,
          users: data.data.assignees.map(item => {
              let user = store.getState().users.find(user => user.name === item);

             return user ? user.id : -1
          }).filter(value => value !== -1)
      }

     // console.log({ user })

      apiClient.post('task/create', reqObject, {
         headers: {
             Accepts: 'application/json',
             Authorization: `Bearer ${user.token}`
         }
      }).then(() => {
          getProjects();
          setNewDataType([...[]])
      })

      //console.log(reqObject)
    }

    const [newDataType, setNewDataType] = useState([]);



    return (
       <>
           <section className={'taskboard-main centered'}>
               <header className={'project-title'}>{ projectData['projectTitle'] }</header>
               <div className={'structured-data'} ref={element => taskContainer.current = element}>
                   {
                    Object.values(projectData).length > 0 ? taskTypes.map((type, index) => {
                           let taskIndex=  Object.keys(projectData.tasks).indexOf(type);
                           return (
                               <div className={'task-list'}
                                    key={index}
                                    onDragOver={event => { event.preventDefault() }}
                                    onDragEnter={event => { event.preventDefault() }}
                                    data-key={type}
                                    onDragEnd={() => clearDropIndicator()}
                                    onDrop={event => handleTaskListDrop(event)}
                               >
                                   <h3 className={'task-header flexbox align-center space-between'}>
                                       <span className={'title'}>{ splitString(type) }</span>

                                       <AiOutlinePlusCircle className={'icon'} onClick={() => setNewDataType([...[type]])} />
                                   </h3>



                                           <div
                                               className={"data-list"}
                                           >
                                               {
                                                   taskIndex !== -1 && projectData.tasks[type].length > 0 ?  projectData.tasks[type].map((data, index) => {
                                                       let { title, task_id, description } = data;
                                                     /*  let dueDate = null, currentDate = new Date(), dueBy = new Date(data.dueBy);
                                                       let dateColor = "";
                                                       if (type === 'inProgress') {
                                                           let timeDiff = dueBy - currentDate;
                                                           dueDate = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

                                                           dateColor = dueDate < 0 ? "#ff0000" : dueDate === 0 ? '#ffc126' : 'rgba(7, 198, 141, .6)';
                                                       }*/




                                                       return (
                                                           <div key={index}>
                                                              {/* <DropIndicator type={type} index={index} />*/}
                                                               <div
                                                                   className={`board-item ${data.animatable ? 'transition-item' : ''}`}
                                                                   draggable={'true'}
                                                                   ref={element => {
                                                                       if (element != null && taskLists.current.indexOf(element) === -1) {
                                                                           taskLists.current.push(element)
                                                                       }
                                                                   }}
                                                                   onDragOver={ event => { event.preventDefault() }}
                                                                   onDragEnter={ event => { event.preventDefault() }}
                                                                   onDrop={event => handleBoardItemDrop(event)}
                                                                   onDragStart={event => handleDragStart(event, type, index)}
                                                                   onDrag={event =>  debounceDrag(event)}
                                                                   onDragEnd={() => clearDropIndicator()}
                                                                   data-key={`${type}-${index}`}
                                                                   onClick={e => {
                                                                       e.stopPropagation();
                                                                       setActiveItem({...{ type, index }});
                                                                   }}
                                                               >


                                                                   <div className={'task-id'} data-key={`${type}-${index}`}>TASK { task_id }</div>
                                                                   <div className={'task-title'}  data-key={`${type}-${index}`}>{ title }</div>

                                                                   <div className={'task-description'}  data-key={`${type}-${index}`}>
                                                                       { limitString( description, 20 )} {description.split(" ").length > 20 ? '...' : ''}
                                                                   </div>
                                                                   <div className={'assigned-to'} data-key={`${type}-${index}`}>
                                                                       {
                                                                           data['users'] ? data['users'].map((assignee, index) => {
                                                                               return(
                                                                                   <div data-key={`${type}-${index}`} className={'user-item'} key={index}>
                                                                                       <div  data-key={`${type}-${index}`} className={'user-image'}>
                                                                                           { assignee.firstname[0] }
                                                                                       </div>

                                                                                       <div data-key={`${type}-${index}`} className={'user'}>
                                                                                           { abbreviateName(`${assignee.firstname} ${assignee.lastname}`) }
                                                                                       </div>
                                                                                   </div>
                                                                               )
                                                                           }): null
                                                                       }
                                                                   </div>

                                                                   {/*{
                                                                       type === 'inProgress' ? <div className={`due-date`} style={{ color: dateColor }}>{ dueDate >= 0 ? "Due" : "Overdue" } {dueDate === 0 ? "today" : dueDate > 0 ? `in ${dueDate} days` : `by ${ Math.abs(dueDate) } days`}</div> : null
                                                                   }*/}
                                                               </div>
                                                           </div>
                                                       )
                                                   }) : null
                                               }
                                           </div>

                               </div>
                           )
                       }) : null
                   }
               </div>
           </section>

           {
               Object.values(activeItem).length > 0 && showItemModal ? <BoardItemModal
                                                                             board={ projectData.tasks[activeItem.type][activeItem.index]}
                                                                             status={activeItem.type}
                                                                             taskTypes={taskTypes}
                                                                             onOutsideClick={ () => setShowItemModal(false) }
                                                                             onClose={() => setShowItemModal(false) }
                                                                             onSave={state => saveNewState(state)}

                                                                             onDelete={() => deleteTask()}
               /> : <></> }

           {
               newDataType.length > 0 ?  <AddBoardModal
                   taskTypes={taskTypes}
                   type={newDataType[0]}
                   onClose={() => {
                       setNewDataType([...[]])
                    }}
                   outsideClick={() => {
                       setNewDataType([...[]])
                   }}
                   onCreate={data => createNewTask(data)}

               /> : null
           }
       </>
    )
}

export default TaskBoard;
