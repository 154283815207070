import React, {useEffect, useState} from "react";
import  '../css/components/navigation.scss';
import {Link} from "react-router-dom";
import { ReactComponent as SignOut } from "../assets/icons/sign-out.svg";
import {GiHamburgerMenu} from "react-icons/gi";
import SpaceImage from "./SpaceImage.tsx";
import {getUserData} from "../helpers/apiMethods.ts";

const Navigation = ({ onToggle }) => {
    let userData = {
        name: "",
        initials: ""
    };

    const [userInfo, setUserInfo] = useState(userData);
    useEffect(() => {
        getUserData().then(response => {
            let user = response.data;

            setUserInfo({name: `${user.firstname} ${user.lastname}`, initials: `${user.firstname[0]}.${user.lastname[0]}`} )
        })
    }, [])
  return (
      <nav className={`navigation fixed top-left full-width`}>
          <div className={'top-panel'}>
              <div className={'panel-item'}>
                  <Link to={'/logout'} className={'flexbox align-center sign-out-panel'}>
                      <div><SignOut /></div>
                      <div className={'sign-out-text'}>Sign out</div>
                  </Link>


                    <GiHamburgerMenu id={'menuIcon'} className={'menu-icon'} onClick={() => onToggle()}/>
              </div>
              <div className={'panel-item logo-item'}>
                <Link to={"/dashboard"}>
                    <img src={require('../assets/logos/logo.png')} alt={'App logo'}/>
                </Link>
              </div>
              <div className={'panel-item user-section'}>
                  <div className={'relative-item user-action'}>
                      <div className={'user-pfp flexbox align-center'}>
                          <SpaceImage/>
                         {/* <ArrowDown />*/}
                      </div>
                      <div className={'user-name'}>
                         <span className={'large-display'}>{ userInfo.name.split(" ")[0] }</span>
                          <span className={'mobile-display'}>{ userInfo.initials }</span>
                      </div>
                  </div>
              </div>
          </div>
      </nav>
  )
}

export default Navigation
