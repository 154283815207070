import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer";

/*const middleware = (store) => (next) => (action) => {
    console.log('Middleware detected action:', action);
    next(action)
}*/
//const storeMiddleware = applyMiddleware(middleware)

const store = configureStore({
    reducer
});

export default store