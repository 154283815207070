import React from 'react';
import { Outlet} from "react-router-dom";

const ProjectDetails = () => {
    return (
        <>
            <Outlet/>
        </>
    )
}

export default ProjectDetails