import { apiClient } from "../api/client";
import store from "../redux/store";
import { addUnderscores } from "./helperFunctions";
import { createProject, clearProjects } from "../redux/actions";

export async function getUserData() {
    let storeUser = store.getState().user;
    if ("id" in storeUser && storeUser) {
        return await apiClient.get(`user/${storeUser.id}`, {
            headers: {
                Accepts: 'application/json',
                Authorization: `Bearer ${storeUser.token}`
            }
        })
    }
}

interface createProjectType {
    title: string,
    budget: string | number,
    startDate: string,
    endDate: string,
    description: string,
    client: string,
    active: boolean,
    id: number
}

export const createNewProject = async (requestObject: createProjectType, token: string) => {
    return await apiClient.post('create/project', requestObject, {
        headers: {
            Accepts: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

export const getUserProjects = async (id: number, token: string) => {
    return await apiClient.get(`get/projects/${id}`, {
       headers: {
           Accepts: 'application/json',
           Authorization: `Bearer ${token}`
       }
    })
}

export const updateCurrentProject = async (requestObject, token) => {
    return await apiClient.post('project/update', requestObject, {
        headers: {
            Accepts: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

export const setAndDispatchProjects = (projectItems) => {
   // console.log({ projectItems })

    let projects = projectItems.map(data => {
        let mainFields = ['id', 'project_id', 'title']
        let projectData = {
            data: {},
            tasks: []
        };

        let dataKeys = Object.keys(data).filter(key => key !== 'tasks');

        dataKeys.forEach(key => {
            if (mainFields.indexOf(key) !== -1)  {
                projectData[key] = data[key]
            } else {
                projectData.data[key] = data[key]
            }
        })

        const taskTypes = ['backlog', 'inProgress', 'inReview', 'done'];
        taskTypes.forEach(task => {
           // console.log({ task, item: projectData.tasks[task]})
           projectData.tasks[task] = data.tasks.filter(item => item.status === addUnderscores(task));
        })
        return projectData
    });


    console.log({ projects })

    store.dispatch(clearProjects())
    projects.forEach(project => {
        store.dispatch(createProject(project));
    })
}
