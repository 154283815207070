import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import "../css/components/stat-counter.scss";

interface propTypes {
    value: number,
    valueTag: string,
    position: string
}
const StatCounter:React.FC<propTypes> = ({ value, valueTag, position }) => {
    const [statValue, setStatValue] = useState(0);

    let incrementValue = useCallback(() => {
        setTimeout(() => {
            if (statValue < value) {
                setStatValue(statValue + 1);
            }
        }, 2)
        //eslint-disable-next-line
    }, [statValue])

    let itemObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                incrementValue();
                observer.unobserve(entry.target)
            }
        })
    });

    let statCounter = useRef(null);

    useEffect(() => {
        if (statValue < value) {
            incrementValue()
        }
        //eslint-disable-next-line
    }, [statValue, incrementValue])

    useEffect(() => {
        itemObserver.observe(statCounter.current)
        //eslint-disable-next-line
    }, [])


    return (
        <div className={'stat-counter'} ref={element => statCounter.current = element}>
            { position === 'before' ?  <span className={'value-before'}>{ valueTag }</span> : null}
            <span className={'main-value'}>{ statValue }</span>
            { position === 'after' ?  <span className={'value-after'}>{ valueTag }</span> : null}
        </div>
    )
}

export default StatCounter
