import React, {useEffect, useRef, useState} from "react";
import store from "../redux/store";
import {MdOutlineClose} from "react-icons/md";
import {BsFillPencilFill} from "react-icons/bs";
import DropdownComponent from "./DropdownComponent";
import Suggestions from "./suggestions";
import {abbreviateName, splitDate, splitString} from "../helpers/helperFunctions";
import Spinner from "./spinner";
import DatePicker from "react-datepicker";
import {cloneDeep} from "lodash";

const BoardItemModal = (props) => {
    const [dropdownData, updateDropdownData] = useState([]);
    let taskTypes = props.taskTypes, taskStatus = props.status;

    useEffect(() => {
        let dropdown = taskTypes.map(type => { return { value: type, label: splitString(type) }});
        updateDropdownData(cloneDeep(dropdown))
    }, [taskTypes])

    let selectedBoard = props.board;
    let editableFields = ['title', 'description'];


    let fields = editableFields.map(field => {
        return {
            field,
            value: selectedBoard[field],
            hasError: false
        }
    })
    const [fieldItems, updateFieldItems] = useState(fields);
    const [newState, updateNewState] = useState({
        data: cloneDeep(selectedBoard),
        status: taskStatus
    });

   /* useEffect(() => {
        console.log('New state:', newState);
    }, [newState])*/
    const [editableField, setEditableField] = useState("");

    let users = store.getState().users.map(user => { return { value: `${user.name.split(" ")[0]} ${user.name.split(" ")[1][0]}.`, label: user.name }})

    const updateState = key => {
        let field = fieldItems.find(item => item.field === key);

        if (field) {
            if (field.value !== '') {
                let updatedState = cloneDeep(newState);
                updatedState.data[key] = field.value

                updateNewState(updatedState)
                setEditableField("");
            }
        }

    }

    const updateFieldValue = (key, event) => {
        event.stopPropagation();
        let value = event.target.value;
        let draft = cloneDeep(fieldItems);
        let field = draft.find(item => item.field === key);

        if (field) {
            let index = draft.indexOf(field);

            if (value === '') {
                draft[index].hasError = true
            } else {
                if (draft[index].hasError) { draft[index].hasError = false }
            }

            draft[index].value = value
        }
        updateFieldItems(draft)
    }

    const getField = key =>{
        let field = fieldItems.find(item => item.field === key);

        return field ? field : '';
    }

    const newAssignee = useRef("");
    const [defaultValue, updateDefaultValue] = useState("");
    const [savingState, setSavingState] = useState(false);
    const [deleteTriggered, setDeleteTriggered] = useState(false);
    const [deletingTask, setDeletingTask] = useState(false);


    /*useEffect(() => {
       // console.log('Default value changed to:', )
        if (resetValue) {
            updateResetValue(false)
        }
    }, [resetValue])*/

    let onDelete = props.onDelete

    useEffect(() => {
        if (deletingTask) {
            setTimeout(() => { onDelete() }, 1000)
        }
    }, [deletingTask, onDelete])

    return (
        <div
            className={'modal board-item-modal flexbox align-center justify-center'}
            onClick={event => {
                if (event.target.closest(".modal-content") == null) {
                    props.onOutsideClick()
                }
            }}
        >
            <div className={'modal-content container-md centered'}>
                <div className={'close-button'} onClick={() => props.onClose()}>
                    <MdOutlineClose className={'close-icon'} />
                </div>

                <div className={'flexbox header-section'}>
                    <div className={'modal-header'}>
                        <span className={'task-id'}>{ newState.data.taskId }</span>
                        <form method={'post'} onSubmit={event => {
                            event.preventDefault();
                            updateState('title')
                        }} className={'task-name flexbox align-center'}>
                            {
                                editableField !== 'title' ?
                                    <span>{ newState.data.title }</span> :
                                    <div className={'input-control'}>
                                        <input
                                            type={'text'}
                                            name={'title'}
                                            className={`${getField('title').hasError ? 'input-error': ''}`}
                                            defaultValue={getField('title').value}
                                            onChange={event => updateFieldValue('title', event)}
                                            placeholder={'Edit title'}/>
                                    </div>
                            }
                            {
                                editableField !== 'title' ?
                                    <BsFillPencilFill
                                        className={'edit-icon'}
                                        onClick={event => {
                                                event.stopPropagation();
                                                setEditableField('title')
                                            }
                                        }
                                    /> : <button
                                        className={'button button-regular'}
                                        onClick={event => {
                                                event.stopPropagation();
                                                 updateState('title')
                                            }
                                        }
                                    >Save</button>
                            }
                        </form>
                    </div>

                    <div className={'flexbox align-center dropdown-section'}>
                        <span className={'label'}>Task status:</span>
                        {
                            <DropdownComponent
                                data={dropdownData}
                                default={taskTypes.indexOf(newState.status)}
                                onSelect={(data) => {
                                    let updatedState = cloneDeep(newState);
                                    updatedState.status = data.value;
                                    updateNewState(updatedState)
                                  //  console.log('Value selected:', data)
                                }}
                            />
                        }
                    </div>
                </div>


                <div className={'item-content'}>

                    {
                        newState.status === 'inProgress' ?  <div className={'flexbox date-container'}>
                            <div className={'input-control'}>
                                <label>Due date</label>
                                <DatePicker
                                    className={'datepicker-component'}
                                    selected={newState.data.dueBy ? splitDate(newState.data.dueBy) : new Date()}
                                    onChange={date => {
                                        let updateState = cloneDeep(newState);
                                        updateState.data.dueBy = date.toISOString().slice(0, 10)
                                        updateNewState(updateState)
                                    }}
                                />
                            </div>
                        </div> : null
                    }
                    <div className={'task-description'}>
                        <header className={'flexbox align-center'}>
                            <span>Task Description</span>
                            {
                                editableField !== 'description' ?<BsFillPencilFill
                                    className={'edit-icon'}
                                    onClick={event => {
                                        event.stopPropagation();
                                        setEditableField('description')
                                    }}
                                /> : null
                            }
                        </header>
                        {
                          editableField === 'description' ?
                                <div className={'input-control'}>
                                    <textarea
                                        name={'description'}
                                        className={`${getField('description').hasError ? 'input-error': ''}`}
                                        defaultValue={getField('description').value}
                                        onChange={event => updateFieldValue('description', event)}
                                    />

                                   <div className={'button-container'}>
                                       <button
                                           onClick={event => {
                                               event.stopPropagation();
                                               updateState('description')
                                           }}
                                           className={'button blue-alternate'}
                                       >
                                           Save
                                       </button>
                                   </div>
                                </div>
                          : <div> { newState.data.description }</div>
                        }
                    </div>

                    <div className={'assignees'}>
                        <div className={'assigned-users'}>
                            <header>Assigned to</header>

                            <div className={'assigned-to'}>
                                {
                                    newState.data.users.length > 0 ? newState.data.users.map((assignee, index) => {
                                        return (
                                            <div className={'user-item'} key={index}>
                                                <div className={'user-image'}>{ assignee.firstname[0] }</div>

                                                <div className={'user'}>{ abbreviateName(`${assignee.firstname} ${assignee.lastname}`) }</div>

                                                <MdOutlineClose
                                                    className={'remove-assignee'}
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                        let updatedState = cloneDeep(newState);
                                                        updatedState.data.users = updatedState.data.users.filter((user,userIndex) => userIndex !== index);
                                                        updateNewState(updatedState)
                                                    }}
                                                />
                                            </div>
                                        )
                                    }) : <div className={'no-results'}>No assignees.</div>
                                }
                            </div>
                        </div>

                        <div className={'assign-new-user'}>
                            <header>Add assignee</header>

                            <div className={'flexbox align-center user-form'}>
                                {
                                    <Suggestions
                                        placeholder={'Add an assignee...'}
                                        defaultValue={defaultValue}
                                        data={users}
                                        onSelect={value => {
                                            newAssignee.current = value
                                          //  console.log('Suggestion value:', newAssignee.current)
                                        }}
                                    />
                                }

                                <button
                                    className={'button'}
                                    onClick={event => {
                                        event.stopPropagation();
                                        let assigneeValue = newAssignee.current;
                                        let valueIndex = store.getState().users.find(user => user.name === assigneeValue);
                                        let existingValue = newState.data.users.find(user => `${user.firstname} ${user.lastname}` === assigneeValue)


                                        if (newAssignee.current !== '' && valueIndex && !existingValue) {

                                            let updatedState = {...newState};
                                            let updatedStateData = {...updatedState.data};
                                            updatedStateData.users =  [...updatedStateData.users, {
                                                firstname: assigneeValue.split(" ")[0],
                                                lastname: assigneeValue.split(" ")[1],
                                                id: valueIndex.id
                                            }]

                                            updatedState.data = updatedStateData
                                           // console.log({ updatedState })
                                            updateNewState({...updatedState})

                                            updateDefaultValue(null);
                                        }
                                    }}
                                >Add</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'flexbox align-center space-between action-buttons'}>
                   <div className={'delete-section flexbox align-center'}>
                       <button
                           className={'button button-danger flexbox justify-center'}
                           onClick={e => {
                               e.stopPropagation();
                               if (!deletingTask) {
                                   setDeleteTriggered(true)
                               }
                           }}
                       >
                           {
                               !deletingTask ? <span>Delete task</span> : <Spinner className={'spinner-icon'} size={20} color={'#f2f2f2'} thickness={1}/>
                           }
                       </button>
                       {
                           deleteTriggered ?  <div className={'delete-confirmation'}>
                               <span>Are you sure you want to delete this task?</span>

                               <div className={'delete-buttons'}>
                                   <button
                                       className={'button button-delete'}
                                       onClick={e => {
                                           e.stopPropagation();
                                           setDeletingTask(true);
                                           setDeleteTriggered(false);
                                        }
                                       }
                                   >Yes, delete task</button>
                                   <button
                                       className={'button button-cancel'}
                                       onClick={e => {
                                           e.stopPropagation();
                                           setDeleteTriggered(false);
                                        }
                                       }
                                   >Cancel</button>
                               </div>
                           </div> : null
                       }
                   </div>

                    <button
                        className={'button button-transparent blue-transparent flexbox justify-center'}
                        onClick={event => {
                            event.stopPropagation();

                           if (!savingState) {
                               setSavingState(true)
                               setTimeout(() => {
                                   props.onSave(newState);
                               }, 1000)
                           }
                        }}
                    >
                        {
                            !savingState ? <span>Save</span> :<Spinner className={'spinner-icon'} size={20} color={'#f2f2f2'} thickness={1}/>
                        }
                    </button>
                </div>


            </div>
        </div>
    )
}

export default BoardItemModal
