import React, {useEffect,  useState } from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import store from "../../redux/store";
import '../../css/views/projects/project-data.scss';
import {FaProjectDiagram} from 'react-icons/fa';
import {MdDashboard, MdOutlineClose} from 'react-icons/md';
import {BsFillPencilFill} from 'react-icons/bs';
import Switch from "../../components/switch";
import Spinner from "../../components/spinner";
import ErrorComponent from "../../components/ErrorComponent";
import {capitalizeFirstLetters, splitString, validateCurrencyValue} from "../../helpers/helperFunctions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {deleteProject, updateProject} from "../../redux/actions";
import {getUserProjects, setAndDispatchProjects, updateCurrentProject} from "../../helpers/apiMethods.ts";
import LoadingComponent from "../../components/LoadingComponent.tsx";
import {apiClient} from "../../api/client";


const ActionBar = ({ initialState, editedState, onDiscard, onSave }) => {
    const [stateChanged, updateStateChanged] = useState(false);
    const [savingState, setSavingState] = useState( false);
    const [clearState, setClearState] = useState(false);

    useEffect(() => {

        let initialStateObject = generateStateObject({...initialState});
        let editedStateObject = generateStateObject({...editedState});

/*
        console.log('State data:', {
            'Initial state': initialStateObject,
            'Edited state': editedStateObject
        })
*/


        let itemsChanged = Object.keys(initialStateObject).filter(key => initialStateObject[key] !== editedStateObject[key]);
        /* itemsChanged.map(key => {
             console.log(initialStateObject[key], editedStateObject[key])
         })
          console.log('Items changed:', itemsChanged.length);*/


        if (itemsChanged.length === 0) {
            updateStateChanged(false);
        } else {
           // console.log('Current state:', stateChanged)
            if (!stateChanged) {
                updateStateChanged( true)
            }
        }


        function generateStateObject(state) {
            return  {...state.data, title: state.title}
        }
        //eslint-disable-next-line
    }, [editedState])

    useEffect(() => {
        if (savingState) {

            let requestObject = {
                title: editedState.title,
                id: editedState.id
            }

            let uncheckedFields = ['pivot', 'created_at', 'updated_at'];

            Object.keys(editedState.data).forEach(key => {
                if (uncheckedFields.indexOf(key) === -1) {
                    let value = editedState.data[key];

                    if (key === 'budget') {
                        value = parseFloat(value)
                    }

                    if (key === 'active') {
                        value = value === 1
                    }
                    requestObject[key] = value;

                }
            })

           // console.log({ requestObject })

            let user = store.getState().user

            requestObject.user_id = user.id;


            updateCurrentProject(requestObject, user.token).then(response => {
               // console.log({ data: response.data })

                store.dispatch(updateProject(editedState))
                onSave()
              //  console.log('New store state:', store.getState());
                setSavingState(false);

                setClearState(true);

                setTimeout(() => {
                    updateStateChanged(false);
                    setClearState(false);
                }, 1500);
            })/*.catch(error => {
                console.log({ error })
            })*/
            //console.log({ requestObject })

        }
        //eslint-disable-next-line
    }, [savingState, updateStateChanged, setSavingState])

    const discardChanges = () => {
        onDiscard()

        updateStateChanged(false);
    }

    return (
        stateChanged ? <div className={'action-bar'}>
            {
                clearState ? <div className={'feedback-text'}>Changes saved!</div> : null
            }

            <div className={'flexbox align-centers button-holder'}>
                <button
                    className={'button button-transparent white-transparent'}
                    onClick={() => discardChanges()}
                >Discard</button>
                <button
                    className={'button button-regular flexbox align-center justify-center'}
                    onClick={() => setSavingState(true)}
                >
                    {
                        savingState ? <Spinner size={20} color={'#02a5ce'} thickness={1}/> : <span>Save</span>
                    }
                </button>
            </div>
        </div> : <></>
    )
}


const ProjectData = () => {
    let parameters = useParams();
    let projectId = parameters['projectId'];

    const [projectData, setProjectData] = useState({});
  // const initialState = useRef({});
   // const editedState = useRef({});
    const [editIndex, setEditIndex] = useState(null);
   const [titleEditMode, setTitleEditMode] = useState(false);
    const [deleteTriggered, setDeleteTriggered] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    const [initialState, setInitialState] = useState({});
    const [editedState, setEditedState] = useState({});


    function getProjectDetails() {
        let user = store.getState().user;

        getUserProjects(user.id, user.token).then(response => {
            setLoadingData(false)
            setAndDispatchProjects(response.data);

            let projectItem = store.getState().projects.find(project => project.project_id === projectId);


            if (projectItem) {
                setInitialState({...projectItem});
                setEditedState({...projectItem});
                setProjectData({...projectItem});
              //  console.log({ projectItem, editedState, initialState})
            }
        }).catch(() => {
            setLoadingData(false)
        })
    }

    useEffect(() => {
        let projectItem = store.getState().projects.find(project => project.project_id === projectId);
        /*
            console.log(projectItem)
            console.log('Project data:', projectData);
        */

        if (projectItem) {
            setInitialState({...projectItem});
            setEditedState({...projectItem});
            setProjectData({...projectItem});
        } else {
            setLoadingData(true)
            setTimeout(() => {
                getProjectDetails()
            }, 1500)
        }
        //eslint-disable-next-line
    }, [projectId])

    const handleEdit = (event) => {
        event.preventDefault();
       // console.log(editedState.data)
        if (!editedState.data['budget'].toString().includes("$")) {
            setEditedState({...editedState})
        }
        let newState = {...editedState};

        setProjectData(newState);
        setEditIndex(null);
    }
/*
    const processValue = (value, key) => {
        return key === "budget"  ? value.slice(1, value.length)  : value;
    }*/
    const saveValue = (key, value) => {
        let stateItem = {...editedState};
        let data = {...stateItem.data};
        if (key === 'budget') {
            value = validateCurrencyValue(value)
        }
        data[key] = value;
        stateItem.data = data;
      setEditedState({...stateItem})
        setProjectData({...stateItem})

    }

    const fullWidthFields = ['description'];
    const unrendered = ['active', 'pivot', 'created_at', 'updated_at'];


    return (
        <>
            {
                   loadingData ? <LoadingComponent text={'Getting project details'}/>
                       : <>
                           {
                               Object.keys(projectData).length > 0 ?
                                   <>

                                       return (
                                       <>
                                           <ActionBar
                                               initialState={initialState}
                                               editedState={editedState}
                                               onSave={() =>  setInitialState({...editedState})}
                                               onDiscard={() => {
                                                   setEditedState({...initialState})
                                                   setProjectData({...initialState});
                                               }}
                                           />
                                           <section className={'page-content'}>
                                               <div className={'header-section'}>
                                                   <div className={'project-widget'}>
                                                       <div className={'project-icon'}>
                                                           <FaProjectDiagram/>
                                                       </div>

                                                       <header>
                                                           {
                                                               !titleEditMode ?  <div className={'flexbox align-center'}>
                                                                   <span className={'color-gradient '}>{ projectData.title }</span>
                                                                   <BsFillPencilFill className={'icon'} onClick={() => setTitleEditMode(true) } />
                                                               </div> : <form className={'flexbox align-center'}>
                                                                   <div className={'input-control'}>
                                                                       <input
                                                                           className={'alternate'}
                                                                           type={'text'}
                                                                           value={projectData.title}
                                                                           onChange={event => {
                                                                               let newData = {...editedState, title: event.target.value}
                                                                               setEditedState(newData);
                                                                               setProjectData(newData)
                                                                           }
                                                                           }
                                                                       />
                                                                   </div>
                                                                   <button
                                                                       type={'submit'}
                                                                       className={'button button-transparent light-transparent'}
                                                                       onClick={() => {
                                                                           let newProjectItems = {...editedState};
                                                                           setProjectData(newProjectItems)
                                                                           setTitleEditMode(false)
                                                                       }}
                                                                   >Save</button>
                                                               </form>
                                                           }

                                                       </header>
                                                   </div>

                                                   <div className={'header-action-panel flexbox space-between align-center'}>
                                                       <div className={'project-status'}>
                                                           <header>Status</header>
                                                           <div className={'flexbox align-center'}>
                                                               <span className={'status-item'}>Inactive</span>
                                                               <Switch
                                                                   checked={projectData.data.active}
                                                                   stateChange={state => {
                                                                       let stateData = {...editedState};
                                                                     //  console.log({ stateData })
                                                                       let stateItem = state ? 1 : 0;
                                                                       stateData.data = {...stateData.data, active: stateItem}


                                                                       setTimeout(() => {
                                                                           setEditedState({...stateData})
                                                                           setProjectData({...stateData})
                                                                       }, 1000)
                                                                   }}
                                                               />
                                                               <span className={'status-item'}>Active</span>
                                                           </div>
                                                       </div>

                                                       <Link to={`/projects/${projectId}/taskboard`} className={'taskboard-item'}>
                                                           <MdDashboard className={'icon'}/>
                                                           <span className={'color-gradient'}>Go to taskboard</span>
                                                       </Link>
                                                   </div>
                                               </div>

                                               <div className={'container-md centered grid double-grid container-form'}>
                                                   {
                                                       Object.keys(projectData.data).filter(key => unrendered.indexOf(key) === -1).map((key, index) => {
                                                           let label = capitalizeFirstLetters(splitString(key).replace(/_/g, " ")), value = projectData.data[key].toString() ;
                                                           return (
                                                               <div className={`data-item ${fullWidthFields.indexOf(key) !== -1 ? 'full-row': ''}`} key={index}>
                                                                   <div className={'item-label flexbox align-center'}>
                                                                       <span>{ label }</span>
                                                                       { fullWidthFields.indexOf(key) !== -1 && editIndex !== index ?  <BsFillPencilFill className={'icon'} onClick={() => { setEditIndex(index) }} /> : null}
                                                                   </div>

                                                                   <div className={'item-data'}>
                                                                       <div className={`flexbox align-center space-between data-value ${editIndex === index ? 'hidden': ''}`}>
                                                                           <span>{key === 'budget' ? '$' : ''}{ value }</span>
                                                                           { fullWidthFields.indexOf(key) === -1 && editIndex !== index ?  <BsFillPencilFill className={'icon'} onClick={() => { setEditIndex(index) }} /> : null}
                                                                       </div>

                                                                       {


                                                                           editIndex === index ? <form onSubmit={event => handleEdit(event)}>
                                                                               <div className={'input-control'}>
                                                                                   {
                                                                                       key.toLowerCase().includes('date') ? <div>
                                                                                               <DatePicker
                                                                                                   selected={new Date(value.split("-")[0], parseInt(value.split("-")[1]) - 1, value.split("-")[2])}
                                                                                                   onChange={date => {
                                                                                                       let stateData = {...editedState};
                                                                                                       let data = {...stateData.data};
                                                                                                       data[key] = date.toISOString().slice(0, 10);
                                                                                                       stateData = {...stateData, data} ;

                                                                                                       setEditedState({...stateData});
                                                                                                       setProjectData({...stateData});
                                                                                                       setEditIndex(null)
                                                                                                   }
                                                                                                   }
                                                                                               />
                                                                                           </div>
                                                                                           : key === 'description' ? <textarea
                                                                                                   data-key={key}
                                                                                                   key={index}
                                                                                                   value={value}
                                                                                                   onChange={event => saveValue(key, event.target.value)}
                                                                                                   placeholder={`Edit ${label}`}
                                                                                               /> :

                                                                                               <input type={'text'}
                                                                                                      data-key={key}
                                                                                                      value={value}
                                                                                                      key={index}
                                                                                                      onChange={event => saveValue(key, event.target.value)}
                                                                                                      placeholder={`Edit ${label}`} />
                                                                                   }
                                                                               </div>

                                                                               <div className={'flexbox'} style={{ display: key !== 'description' ? 'none': 'flex' }}>
                                                                                   <input type={'submit'} value={'Save'}  className={'button blue-alternate'}/>
                                                                               </div>

                                                                           </form>: null
                                                                       }

                                                                   </div>
                                                               </div>

                                                           )
                                                       })
                                                   }

                                               </div>

                                               <div className={'bottom-section'}>
                                                   <button
                                                       onClick={() => setDeleteTriggered(true)}
                                                       className={'button button-danger button-transparent'}
                                                   >
                                                       Delete project
                                                   </button>
                                               </div>
                                           </section>


                                           <DeleteModal
                                               open={deleteTriggered}
                                               onClose={() => setDeleteTriggered(false)}
                                               projectId={projectData.id}
                                           />
                                       </>
                                       )
                                   </> : <ErrorComponent error={'Error fetching project'} onRefresh={() => getProjectDetails()}/>
                           }
                       </>
            }
        </>
    )
}


const DeleteModal = ({ open, onClose, projectId }) => {
    let navigate = useNavigate();
    const [deletingProject, setDeletingProject] = useState(false);

    return (
       open ?  <div className={'modal'}
                                onClick={event => {
                                    if (event.target.closest(".modal-content") == null){
                                        onClose()
                                    }
                                }
                                }
        >
            <div className={'modal-content delete-modal container-sm '}>
                <div
                    className={'close-button'}
                    onClick={() => {
                       onClose()
                    }
                    }
                ><MdOutlineClose className={'close-icon'} /></div>
                <header>Are you sure you want to delete this project ? This action cannot be undone.</header>

                <div className={'button-items flexbox justify-center'}>
                    <button
                        onClick={() => {

                            onClose()
                        }}
                        className={'button button-transparent white-transparent'}
                    >Cancel</button>
                    <button
                        className={'button button-transparent button-danger'}
                        onClick={() => {
                            setDeletingProject(true);

                            let user = store.getState().user;

                            let reqObject = {
                                id: projectId,
                                user_id: user.id
                            }

                            apiClient.post('project/delete', reqObject, {
                                headers: {
                                    Accepts: `application/json`,
                                    Authorization: `Bearer ${user.token}`
                                }
                            }).then(() => {
                                store.dispatch(deleteProject(projectId));

                              //  console.log('New state:', store.getState())
                                navigate('/projects');
                            })

                           /* setTimeout(() => {
                                store.dispatch(deleteProject(projectId));

                                console.log('New state:', store.getState())
                                navigate('/projects');
                            }, 2000)*/
                        }
                        }
                    >
                        {
                            deletingProject ? <Spinner className={'spinner-icon'} size={20} color={'#f2f2f2'} thickness={1}/> : <span>Delete</span>
                        }
                    </button>
                </div>
            </div>
        </div> : <></>

    )
}

export default ProjectData;
