import axios from "axios";

const baseLink = "https://api.msstarling.com/api/"
//const baseLink = "http://127.0.0.1:8000/api/"
axios.defaults.withCredentials = true

export const apiClient = axios.create({
    baseURL: `${baseLink}task-management/`
})

export const baseApi = axios.create({
    baseURL: baseLink
})
