import React, {useEffect} from "react";
import Spinner from "../components/spinner";
import { useNavigate } from "react-router-dom";
import {addUser} from "../redux/actions";
import store from "../redux/store";


const Logout = () => {
    let navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('savedUser');

        store.dispatch(addUser({}));

        setTimeout(() => {
            navigate('/login')
        }, 1500)
        //eslint-disable-next-line
    }, [])
    return <div className={'full-width full-height flexbox justify-center column align-center'}>
        <Spinner size={16} color={'#f3f3f3'} thickness={2}/>
    </div>
}

export default Logout
